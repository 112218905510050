import { Col, Row } from "react-bootstrap";
import { useApi } from "../../../../../hooks/useApi";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheckCheck, MessageCircleQuestion } from "lucide-react";
import { FaqState } from "./FaqState";
import { FaqDetailSkeleton } from "./FaqDetailSkeleton";
import { FaqDelete } from "../FaqDelete/FaqDelete";
import { FaqCreate } from "../FaqCreate/FaqCreate";

export const FaqDetail = () => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [showUpdateFaq, setShowUpdateFaq] = useState<boolean>(false);

  const { data, loading, resolve } = useApi({ method: "GET" });

  const params = useParams();

  const handleUpdate = () => {
    resolve({ url: "/common/faqs/" + params?.id });
  };

  useEffect(() => {
    resolve({ url: "/common/faqs/" + params?.id });
  }, [params?.id]);

  if (loading || updating) return <FaqDetailSkeleton />;

  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="d-md-flex align-items-center mb-3 mx-2">
            <div className="mb-md-0 mb-3">
              <h5 className="font-weight-bold mb-0">Detalhes da FAQ</h5>
            </div>
            {data?.data && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-white btn-icon d-flex align-items-center mb-0 ms-md-auto mb-sm-0 mb-2 me-2"
                >
                  <span className="btn-inner--icon">
                    <span
                      className={
                        "p-1 bg-" +
                        (data?.data?.isActive ? "success" : "danger") +
                        " rounded-circle d-flex ms-auto me-2"
                      }
                    ></span>
                  </span>
                  <span className="btn-inner--text">
                    {data?.data?.isActive ? "Activo" : "Desactivado"}
                  </span>
                </button>

                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-dark btn-icon d-flex align-items-center mb-0 me-2 dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Ações
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <FaqState
                        faq={data?.data}
                        handleUpdate={handleUpdate}
                        setUpdating={setUpdating}
                      >
                        <span className="dropdown-item text-sm">
                          {data?.data?.isActive ? "Desactivar" : "Activar"}
                        </span>
                      </FaqState>
                    </li>
                    <li>
                      <span
                        className="dropdown-item text-sm"
                        onClick={() => setShowUpdateFaq((v) => !v)}
                      >
                        Editar
                      </span>
                    </li>
                    <li>
                      <FaqDelete faq={data?.data}>
                        <span className="dropdown-item text-sm">Eliminar</span>
                      </FaqDelete>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row className=".not-fluid mt-5"></Row>
      <>
        <Row>
          <Col md={4} sm={12} xs={12}>
            <div className="card border shadow-xs mb-4">
              <div className="card-body text-start p-3 w-100">
                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                  <MessageCircleQuestion size={15} />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="w-100">
                      <p className="text-dark  mb-1 font-weight-bold h4">
                        {data?.data?.question}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8} sm={12} xs={12}>
            <div className="card border shadow-xs mb-4">
              <div className="card-body text-start p-3 w-100">
                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                  <CheckCheck size={15} />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="w-100">
                      <div
                        className="prose text-sm text-justify"
                        dangerouslySetInnerHTML={{ __html: data?.data?.answer }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
      <FaqCreate
        refresh={() => handleUpdate()}
        show={showUpdateFaq}
        setShow={setShowUpdateFaq}
        faq={data?.data}
        update={true}
      />
    </div>
  );
};
