export function convertLinksToHtml(text: any) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url: any) => {
    if (/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(url)) {
      return `<img src="${url}" alt="Image" style="max-width: 100%; " />`;
    }

    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

export const parseText = (inputText: string): string => {
  let final = convertLinksToHtml(inputText);
  return final;
};