import React from 'react'

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { Controller } from 'react-hook-form';
import { Stack } from 'react-bootstrap';
import moment from 'moment';

export const MyDTPicker = (props: any) => {

    const { control, name, ...p } = props
    const renderInput = (props: any, openCalendar: any, closeCalendar: any) => {
        

        return (<>

            <div style={{ border: 'solid 1px #e0e0e0', borderRadius:5 }}>
                <Stack direction='horizontal' >
                    <input {...props} 
                    // value={moment(props.value).format('DD/MM/YYYY HH:mm')}
                     style={{ border: 'none' }} />
                    <button type='button' onClick={openCalendar} style={{border:'none', backgroundColor:'transparent'}}>
                    <i className="fa-solid fa-calendar" style={{color:'#555'}}></i>
                    </button>
                </Stack>

            </div>
        </>);
    }
    return <Controller
        render={({ field }: any) => <>

            <Datetime
                style={{ minHeight: 200 }}
                {...field}
                renderInput={renderInput}
            /></>
        }
        name={name}
        control={control}
        defaultValue={props.value}
    />
        ;
}
