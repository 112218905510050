import { ImagePlus } from "lucide-react";
import React from "react";

export function AddImageFile({
  onSelectImage,
}: {
  onSelectImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <>
      <label className="btn btn-sm shadow-none" htmlFor="imageFile">
        <ImagePlus size={15} />
      </label>
      <input
        type="file"
        accept="image/*"
        id="imageFile"
        className="d-none"
        onChange={onSelectImage}
      />
    </>
  );
}
