import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Permission } from "../../../../../components/Permission/Permission";

const UserDetailsNavBar = ({ setTab, tab }: any) => {
  return (
    <Navbar collapseOnSelect expand="lg" sticky="top">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link
              href="#userdatas"
              onClick={() => setTab(0)}
              active={tab === 0}
            >
              Dados Pessoais
            </Nav.Link>
            <Permission permissions={["ADMIN"]}>
              <Nav.Link
                eventKey={2}
                href="#settings"
                onClick={() => setTab(3)}
                active={tab === 3}
              >
                Definicões
              </Nav.Link>
            </Permission>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default UserDetailsNavBar;
