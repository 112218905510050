import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { apiRequest } from '../../../../../../utils/api'

import ContactData from './DeleteData'
import BioData from './BioData'
import AccessData from './AccessData'
import DisableData from './DisableData'
import DeleteData from './DeleteData'
import UserRole from './UserRole'

const PersonalData = ({ user, refresh }: any) => {

  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (user?.id) {
      apiRequest({
        endpoint: '/users-data/' + user.id,
        method: 'get',
      }).then(({ data }: any) => {
        setData({ id: user?.id, email: user?.email, ...data })
      }).catch(err => console.error(err))
    }
  }, [user, refresh])



  return (<>

    <AccessData user={user} refresh={refresh} />
    
    <UserRole user={user} refresh={refresh} />

    <DisableData user={user} refresh={refresh} />

    <DeleteData userData={data} user={ user} refresh={refresh} />

  </>
  )
}


export default PersonalData
