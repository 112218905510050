import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useApi } from "../../../../../hooks/useApi";
import {
  ProgressLoading,
  PulseLoading,
} from "../../../../Common/components/Loading";
import { FaqCreateFormSkeleton } from "./FaqCreateFormSkeleton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// Registrar o formato 'size'



interface PropertyFormInputs {
  question: string;
  answer: string;
}

export const FaqCreateForm = ({
  refresh,
  setShow,
  update = false,
  faq,
}: {
  faq?: any;
  update?: boolean;
  refresh: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PropertyFormInputs>({
    defaultValues: {
      answer: faq?.answer,
    },
  });

  const { data, error, loading, resolve } = useApi({
    method: "POST",
    url: "/common/faqs",
  });

  const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) =>
    formSubmit(data);

  const formSubmit = async (body: PropertyFormInputs) => {
    if (update)
      resolve({ body, method: "PUT", url: "/common/faqs/" + faq?.id });
    else resolve({ body });
  };

  useEffect(() => {
    if (data) {
      toast.success(`FAQ ${update ? "atualizado" : "criado"} com sucesso!`);
      refresh();
      setShow((v) => !v);
    } else if (error) {
      toast.error(
        "Não foi possivel eliminar o FAQ, por favor tente mais tarde; se o erro persistir contacte o administrador de sistema!"
      );
    }
  }, [data]);

  if (loading) return <FaqCreateFormSkeleton />;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: "600px", margin: "0 auto", padding: 15 }}
    >
      <div>
        <label htmlFor="title">Pergunta</label>
        <input
          className="form-control ps-0 shadow-none"
          placeholder="Pergunta"
          type="text"
          {...register("question", { required: "A pergunta é obrigatória!" })}
          defaultValue={faq?.question}
        ></input>
        <span className="error">
          {errors.question && <p>{errors.question.message}</p>}
        </span>
      </div>

      <div>
        <label className="font-medium">Resposta</label>
        <Controller
          name="answer"
          control={control}
          rules={{ required: "A resposta é obrigatória!" }}
          render={({ field }) => (
            <ReactQuill
              {...field}
              theme="snow"
              placeholder="Digite a resposta..."
              defaultValue={field.value}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
        {errors.answer && (
          <p className="text-red-500 text-sm mt-1">{errors.answer.message}</p>
        )}
      </div>

      <hr />

      <button
        type="submit"
        className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2"
      >
        {loading ? <PulseLoading /> : <span className="btn-inner--icon"></span>}
        <span className="btn-inner--text">
          {update ? "Atualizar" : "Registar nova"}
        </span>
      </button>
    </form>
  );
};
