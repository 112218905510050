import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Properties from "./Properties";
import { PropertiesDetail } from "./Properties/PropertiesDetail/PropertiesDetail";
import { TalentPool } from "./TalentPool/TalentPool";
import { ProgramDetails } from "./TalentPool/Programs/ProgramDetails";
import { NotFound } from "../Common/ErrorPages";
import Chat from "../ManagerBackOffice/HelpDesk/Chat/Chat";
import { UserDetails } from "../ManagerBackOffice/Users/UserDetails";
import { UserDetailProfile } from "../ManagerBackOffice/Users/UserDetails/UserDetailProfile";
import { Faq } from "./Faq/Faq";

export const UserBackOfficeRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="properties" element={<Properties />} />
      <Route path="talent-pool" element={<TalentPool />} />
      <Route path="talent-pool/:id" element={<ProgramDetails />} />
      <Route path="properties/:id" element={<PropertiesDetail />} />

      <Route path="chat" element={<Chat />} />
      <Route path="profile" element={<UserDetailProfile />} />
      <Route path="faqs" element={<Faq />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
