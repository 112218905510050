import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { apiRequest } from "../../../../utils/api";
import UserDetailsNavBar from "./components/UserDetailsNavBar";
import PersonalData from "./components/PersonalData";
import { ProgressLoading } from "../../../Common/components/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { UploadForm } from "../../../../components/Upload/Upload";

export const UserDetailProfile = () => {
  const auth: any = useSelector((state: RootState) => state.auth);

  const location = useLocation();
  const [tab, setTab] = useState<number>(0);
  const [user, setUser] = useState<any>({});
  const [refresh, setRefresh] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const CurrentTab: any = [PersonalData][tab];

  useEffect(() => {
    setLoading(true);
    apiRequest({
      endpoint: "/users/" + auth?.user?.id,
      method: "get",
    })
      .then(({ data }: any) => {
        setUser(data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [refresh, auth, location]);

  return (
    <div>
      <div
        className="pt-7 pb-6 bg-cover"
        style={{
          backgroundImage: "url('/assets/img/header-orange-purple.jpg')",
          backgroundPosition: "bottom",
        }}
      ></div>
      <div className="container">
        <div className="card card-body py-2 bg-transparent shadow-none">
          <div className="row">
            <div className="col-auto">
              {user && (
                <UploadForm userData={user.userData} setRefresh={setRefresh} />
              )}
              <div className="avatar avatar-2xl rounded-circle position-relative mt-n7 border border-gray-100 border-4">
                <img
                  src={user?.userData?.avatar ?? "/img/unknow.png"}
                  alt="profile_image"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-auto my-auto">
              <div className="h-100">
                <h3 className="mb-0 font-weight-bold">{user?.name}</h3>
                <p className="mb-0">{user?.email}</p>
                {user?.isActive ? (
                  <span className="badge border border-success text-success bg-success">
                    Activo
                  </span>
                ) : (
                  <span className="badge border border-danger text-danger bg-danger">
                    Desactivado
                  </span>
                )}
                <span className="badge border text-secondary">
                  {" "}
                  {user?.role}
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3 text-sm-end">
              <UserDetailsNavBar setTab={setTab} tab={tab} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3 py-3">
        {loading && <ProgressLoading />}
        <div className="row">
          <CurrentTab user={user} refresh={() => setRefresh(Math.random())} />
        </div>
      </div>
    </div>
  );
};
