import { File, ImagePlus } from "lucide-react";
import React from "react";

export function AddFile({
  onSelectFile,
}: {
  onSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <>
      <label className="btn btn-sm shadow-none" htmlFor="file">
        <File size={15} />
      </label>
      <input
        type="file"
        accept=".pdf"
        id="file"
        className="d-none"
        onChange={onSelectFile}
      />
    </>
  );
}
