import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { ChatList } from "./chat-list/ChatList";
import ChatContent from "./chat-content/ChatContent";
import styles from "./ChatContainer.module.scss";

export function ChatContainer({
  currentChat = undefined,
  full = false,
}: {
  currentChat: any;
  full: boolean;
}) {
  const auth = useSelector((store: RootState) => store.auth);

  const user = auth?.user;

  const [member, setMember] = useState<any>();
  const [selectedChat, selectChat] = useState<any>();

  useEffect(() => {
    if (selectedChat) {
      setMember(
        selectedChat?.memberships?.find(
          ({ userId }: any) => userId === user?.id
        )
      );
    }
  }, [selectedChat]);

  useEffect(() => {
    if (currentChat?.id) {
      selectChat(currentChat);
    }
  }, [currentChat]);

  return (
    <div
      className="container position-relative rounded   border-bottom border-right"
      style={{ height: "500px", margin:0, padding:0 }}
    >
      {full && (
        <div className={`d-flex flex-column ${styles["side-painel"]}`}>
          {/** footer */}
          {!currentChat && (
            <div className="">
              <div className="d-flex align-items-end justify-content-center gap-2">
                <img
                  width={50}
                  className="img-thumbnail rounded-circle border-success border-2 p-0"
                  alt=""
                  src={
                    user?.userData?.avatar ??
                    "http://emilcarlsson.se/assets/mikeross.png"
                  }
                />
                <p className="text-light">{user?.name || user?.email}</p>
              </div>
            </div>
          )}

          <div className="mt-3">
            <ChatList
              user={user}
              selectChat={selectChat}
              selectedChat={selectedChat}
            />
          </div>
        </div>
      )}

      <div className={full ? styles["content"] : ""}>
        <ChatContent
          full={full}
          member={member}
          chat={selectedChat}
          user={user}
        />
      </div>
    </div>
  );
}
