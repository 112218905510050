import moment from "moment";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import MentorList from "../Mentors/MentorList";

export const EventDetail = ({ event, handleEditEvent }: any) => {
  return (
    <form style={{ minWidth: "400px", margin: "0 auto", padding: 15 }}>
      <Row>
        <Col xs={12}>
          <label htmlFor="title">Titulo</label>
          <h5>{event?.title}</h5>
        </Col>

        <Col xs={12}>
          <label htmlFor="title">Descrição</label>
          <div>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: event?.description }}
            ></div>
          </div>
        </Col>

        <Col md={6}>
          <label htmlFor="title">Data e hora de inicio</label>

          <h5>
            {moment(event.date).format("DD/MM/YYYY")}
            <small>{moment(event.date).format("HH:mm")}</small>
          </h5>
        </Col>

        <Col md={6}>
          <label htmlFor="title">Duração (Horas)</label>
          <h5>{event?.duration}</h5>
        </Col>

        <Col>
          <hr />
          <h5>Mentores</h5>
          <MentorList event={event} show={true} />

          <Stack direction="horizontal" gap={3} className="py-2">
            <button
              type="button"
              onClick={() => handleEditEvent(event)}
              className="btn btn-dark btn-icon d-flex align-items-center me-2"
            >
              <span className="btn-inner--text">Editar</span>
            </button>
          </Stack>
        </Col>
      </Row>
    </form>
  );
};

export const EventDetailModal = ({
  show,
  setShow,
  refresh,
  event = undefined,
  handleEditEvent,
}: {
  refresh: () => void;
  show: boolean;
  event?: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditEvent: (event: any) => void;
}) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        style={{ zIndex: 9999999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Evento | Tarefa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventDetail
            event={event}
            closeModal={handleClose}
            refresh={refresh}
            handleEditEvent={handleEditEvent}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
