import React, { useEffect, useState } from "react";

import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../../utils/api";
import { LayoutBanner } from "../LayoutBanner";

export const TalentPool = () => {
  const navigate = useNavigate();

  const [{ data: users, ...data }, setUsers] = useState<any>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [showUserCreateModal, setShowUserCreateModal] = useState(false);
  useEffect(() => {
    apiRequest({
      endpoint: "/users",
      method: "get",
      urlQueryParams: { page: currentPage, limit: 6 },
    })
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => console.error(err));
  }, [currentPage]);

  return (
    <>
      <LayoutBanner name="Laboratório de Talentos" />

      <div className="row">
        <div
          className="col-xl-3 col-sm-6 mb-xl-0"
          onClick={() => navigate("/admin/talent-pool/programs")}
        >
          <div className="card border shadow-xs mb-4">
            <div className="card-body text-start p-3 w-100">
              <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                <svg
                  height="16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="w-100">
                    <p className="text-sm text-secondary mb-1">Programs</p>
                    <h4 className="mb-2 font-weight-bold"></h4>
                    <div className="d-flex align-items-center">
                      <span className="text-sm text-success font-weight-bolder">
                        <i className="fa fa-chevron-up text-xs me-1"></i>..
                      </span>
                      <span className="text-sm ms-1">Ver Programas</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
