import { apiRequest } from "./api";

export interface IExtractPreview {
    url?: string;
    image?: string;
    title?: string;
    description?: string;
}

export async function extractPreview(message: string) {
    const urlMatch = message.match(/\bhttps?:\/\/\S+/gi)?.[0];
    if (!urlMatch) return null;

    try {

        const res = await apiRequest({
            method: "get", endpoint: "/common/messages/preview", urlQueryParams: {
                url: urlMatch
            }
        });

        return res;
    } catch (error) {
        console.error("Erro ao buscar preview:", error);
        return null;
    }
}