import React, { useContext, useEffect, useState } from "react";
import { apiRequest } from "../../../../../../../utils/api";
import EventCreate from "./EventCreate";
import moment from "moment";
import { env } from "process";
import EventDelete from "./EventDelete";
import {
  ProgressLoading,
  PulseLoading,
} from "../../../../../../Common/components/Loading";
import { loadavg } from "os";
import {
  Accordion,
  AccordionContext,
  Button,
  ButtonGroup,
  ListGroup,
  Stack,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../app/store";
import { toast } from "react-toastify";
const EventList = ({
  enrolled,
  addUserProgress,
  program,
  setEvents: setOuterEvents,
}: any) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [{ data: events, ...data }, setEvents] = useState<any>({});

  const [currecntEvent, setCurrentEvent] = useState<any>();
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (program?.id) {
      setLoading(true);
      apiRequest({
        endpoint: "/events",
        method: "get",
        urlQueryParams: { limit: 20, programId: program?.id },
      })
        .then((data) => {
          setEvents(data);
          setOuterEvents(data?.data);

        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [program, refresh]);

  return (
    <>
      <Accordion defaultActiveKey="0">
        {events?.map((event: any, index: number) => (
          <>
            <AccordionItem
              enrolled={enrolled}
              refresh={() => setRefresh(Math.random())}
              addUserProgress={addUserProgress}
              user={user}
              index={index}
              event={event}
              setCurrent={setCurrentEvent}
            />
          </>
        ))}
      </Accordion>
      {loading && <ProgressLoading />}
    </>
  );
};

const AccordionItem = ({
  enrolled,
  addUserProgress,
  user,
  event,
  index,
  setCurrent,
  refresh,
}: any) => {
  return (
    <>
      <Accordion.Item eventKey={`${index}`}>
        <Accordion.Header onClick={() => setCurrent(event)}>
          {index + 1}. {event?.title}
        </Accordion.Header>
        <ExecutEvent
          addUserProgress={addUserProgress}
          user={user}
          event={event}
          refresh={refresh}
          enrolled={enrolled}
        />
      </Accordion.Item>
    </>
  );
};

const ExecutEvent = ({
  user,
  event,
  addUserProgress,
  refresh,
  enrolled,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enrolledEvent, setEnrolledEvent] = useState<boolean>(false);

  const entolledState = () => {
    apiRequest({
      endpoint: "/user-events",
      method: "get",
      urlQueryParams: { limit: 1, userId: user?.id, eventId: event?.id },
    })
      .then((data) => {
        if (data?.data?.length > 0) {
          setEnrolledEvent(true);
          addUserProgress(event);
        }
      })
      .catch((_: any) => null)
      .finally(() => setLoading(false));
  };

  useEffect(entolledState, []);

  const enroll = () => {
    setLoading(true);
    apiRequest({
      endpoint: "/user-events",
      method: "post",
      body: { userId: user?.id, eventId: event?.id },
    })
      .then((data) => {
        if (data?.id) {
          toast.success("Participação na tarefa feita com sucesso");
          setEnrolledEvent(true);
          addUserProgress(event);
        }
        refresh();
      })
      .catch(({ data }) => console.log(data))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Accordion.Body>
        <Stack direction="horizontal" gap={3}>
          <ButtonGroup aria-label="Basic example">
            <Button variant="light">
              Data
              <br />
              <b>{moment(event?.date).format("DD/MM/YYYY")}</b>
              <br />
              <small>{moment(event?.date).format("dddd")}</small>
            </Button>
            <Button variant="light">
              Horário
              <br />
              <b>{moment(event?.date).format("HH:mm")}</b>
              <br /> às{" "}
              <b>
                {moment(event?.date)
                  .add(event?.duration, "hours")
                  .format("HH:mm")}
              </b>
            </Button>
          </ButtonGroup>
          <div className="ms-auto">
            {enrolled && (
              <>
                {event?.isActive ? (
                  <>
                    {enrolledEvent ? (
                      <Button variant="outline-success" disabled>
                        Participando
                      </Button>
                    ) : (
                      <Button onClick={enroll} variant="secondary">
                        {loading ? <PulseLoading /> : "Participar"}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button variant="outline-danger" size="sm" disabled>
                    Tarefa desactivada
                  </Button>
                )}
              </>
            )}
          </div>
          <hr />
        </Stack>
        {event?.users?.length > 0 &&
          event?.isActive &&
          enrolled !== undefined && (
            <>
              <hr />
              <div dangerouslySetInnerHTML={{ __html: event?.description }} />
            </>
          )}
      </Accordion.Body>
    </>
  );
};
export default EventList;
