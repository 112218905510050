import React from "react";

const ChatContentSkeleton = () => {
  return (
    <div className="d-flex flex-column position-absolute p-4 w-100">
      {/* Header do Chat */}
      <div className="d-flex align-items-center gap-2 justify-between shadow-md p-3 px-4 py-3">
        <div className="placeholder-glow">
          <div className="placeholder rounded-circle" style={{ width: "35px", height: "35px" }}></div>
        </div>
        <div className="placeholder-glow w-50">
          <div className="placeholder col-8"></div>
        </div>
      </div>

      {/* Lista de mensagens (simuladas) */}
      <div className="pt-4 px-4 flex-grow-1">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="d-flex flex-column mb-3">
            <div className="placeholder-glow">
              <div className="placeholder rounded w-75" style={{ height: "20px" }}></div>
            </div>
            <div className="placeholder-glow">
              <div className="placeholder rounded w-50 mt-2" style={{ height: "20px" }}></div>
            </div>
          </div>
        ))}
      </div>

      {/* Campo de entrada de mensagem */}
      <div className="p-4">
        <div className="placeholder-glow">
          <div className="placeholder w-100" style={{ height: "40px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ChatContentSkeleton;
