import { LayoutBanner } from "../../LayoutBanner";

export const FaqManagerSkeleton = () => {
  return (
    <>
      <LayoutBanner name="FAQs" description="Gestão das perguntas frequentes" />

      <div className="row">
        <div className="col-12">
          <div className="card border shadow-xs mb-4">
            <div className="card-header border-bottom pb-0">
              <div className="d-sm-flex align-items-center">
                <div className="placeholder-wave">
                  <span className="placeholder col-8"></span>
                </div>
                <div className="ms-auto d-flex">
                  <button
                    type="button"
                    className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2 disabled"
                  >
                    <span className="placeholder col-6"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                <div className="input-group w-sm-25 ms-auto">
                  <span className="input-group-text text-body">
                    <span className="placeholder col-6"></span>
                  </span>
                  <input
                    type="text"
                    className="form-control shadow-none placeholder"
                    disabled
                  />
                </div>
              </div>
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="text-secondary text-xs font-weight-semibold opacity-7">
                        FAQ
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Estado
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Data de Registo
                      </th>
                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(6)].map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div className="placeholder col-2"></div>
                            <div className="d-flex flex-column justify-content-center ms-2">
                              <span className="placeholder col-6 mb-1"></span>
                              <span className="placeholder col-8"></span>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle text-center">
                          <span className="placeholder col-4"></span>
                        </td>
                        <td className="align-middle text-center">
                          <span className="placeholder col-3"></span>
                        </td>
                        <td className="align-middle">
                          <span className="placeholder col-2"></span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="border-top py-3 px-3 d-flex align-items-center">
                <p className="font-weight-semibold mb-0 text-dark text-sm">
                  <span className="placeholder col-4"></span>
                </p>
                <div className="ms-auto">
                  <button className="btn btn-sm btn-white mb-0 disabled">
                    <span className="placeholder col-4"></span>
                  </button>
                  <button className="btn btn-sm btn-white mb-0 disabled ms-2">
                    <span className="placeholder col-4"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
