import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { ChatContainer } from "./ChatContainer";

const Chat = ({ currentChat = undefined, full = false }: any) => {
  const auth = useSelector((store: RootState) => store.auth);

  const user = auth?.user;

  const [member, setMember] = useState<any>();
  const [selectedChat, selectChat] = useState<any>();

  useEffect(() => {
    if (selectedChat) {
      setMember(
        selectedChat?.memberships?.find(
          ({ userId }: any) => userId === user?.id
        )
      );
    }
  }, [selectedChat]);

  useEffect(() => {
    if (currentChat?.id) {
      selectChat(currentChat);
    }
  }, [currentChat]);

  return <ChatContainer currentChat={selectedChat} full={full} />;
};

export default Chat;
