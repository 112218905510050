import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { apiRequest } from "../../../../../../utils/api";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ProgressLoading } from "../../../../../Common/components/Loading";

const ProgramState = ({ program, children, refresh, setLoading }: any) => {
  const handleDeletProgram = () => {
    setLoading(true);
    apiRequest({
      endpoint: "/programs/" + program?.id,
      method: "put",
      body: { isActive: !program?.isActive },
    })
      .then(({ data }: any) => {
        if (data?.id !== undefined) {
          toast.success(
            "Estado do Programa " +
              program?.title +
              " Alterado para " +
              (data?.isActive ? "Activado" : "Desactivado") +
              "  com sucesso!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
          refresh();
        } else {
          toast.error(
            "Não foi possivel Alterar o estado do Programa, por favor tente mais tarde,. se o erro persistir contacte o administrador de sistema: " +
              data?.message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <span onClick={handleDeletProgram}>{children}</span>
    </>
  );
};

export default ProgramState;
