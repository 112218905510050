import { Camera } from "lucide-react";
import { useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import { PulseLoading } from "../../pages/Common/components/Loading";
import { ImageCropper } from "./ImageCropper";

export function UploadForm({
  setRefresh,
  userData
}: {
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  userData: any
}) {
  const [show, setShow] = useState(false);

  const handleModal = () => setShow((s) => !s);

  return (
    <>
      <div className="position-relative p-2">
        <Camera
          size={40}
          className="cursor-pointer shadow"
          style={{
            position: "absolute",
            zIndex: "99",
            backgroundColor: "#fff",
            right: 0,
            padding: "10px",
            borderRadius: "100%",
          }}
          onClick={() => handleModal()}
        />
      </div>

      <Modal show={show} onHide={handleModal} size="lg" backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>Nova foto de perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageCropper userData={userData} handleModal={handleModal} setRefresh={setRefresh} />
        </Modal.Body>
      </Modal>
    </>
  );
}
