import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../../utils/api";
import moment from "moment";
import { Link } from "react-router-dom";
import { ProgramCreate } from "./ProgramCreate/ProgramCreate";
import { ProgressLoading } from "../../../Common/components/Loading";
import Pagination from "../../../../components/Pagination";
import { LayoutBanner } from "../../LayoutBanner";
import { Plus } from "lucide-react";
import { useApi } from "../../../../hooks/useApi";
import { ProgramsSkeleton } from "./ProgramsSkeleton";

export const Programs = () => {
  const [programs, setPrograms] = useState<any[]>();
  const [filterData, setFilterData] = useState<string>();
  const [refresh, setRefresh] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [showProgramCreateModal, setShowProgramCreateModal] = useState(false);

  const {
    loading,
    resolve,
    data: dataFetched,
  } = useApi({
    method: "GET",
    url: "/programs",
  });

  const handleKeyDownFilter = (event: any) => {
    if (!(event.key === "Enter")) return;

    if (filterData && filterData.length > 0) {
      resolve({
        params: { title: filterData },
      });
    }
  };

  useEffect(() => {
    resolve({
      params: { page: currentPage, limit: 6 },
    });
  }, [currentPage, refresh]);

  useEffect(() => {
    if (dataFetched?.data) setPrograms(dataFetched?.data);
  }, [dataFetched]);


  return (
    <>
      <LayoutBanner
        name="Programas"
        description={`A Laboratório de Talentos oferece programas de mentoria e
                desenvolvimento profissional para ajudar você a alcançar seus
                objetivos de carreira. Com orientação de especialistas em
                diversas áreas, nossos programas são uma oportunidade única para
                crescer, aprender novas habilidades e se destacar no mercado de
                trabalho. Junte-se a nós e transforme seu talento em resultados
                concretos!`}
      />

      {loading ? (
        <ProgramsSkeleton />
      ) : (
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">
                      Programas já registrados
                    </h6>
                  </div>
                  <div className="ms-auto d-flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-white me-2"
                      onClick={() => {
                        setFilterData("");
                        resolve();
                      }}
                    >
                      Ver todos
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2"
                      onClick={() => setShowProgramCreateModal(true)}
                    >
                      <Plus size={14} className="me-2" />
                      <span className="btn-inner--text">Registar novo</span>
                    </button>
                    <ProgramCreate
                      refresh={() => setRefresh(Math.random())}
                      show={showProgramCreateModal}
                      setShow={setShowProgramCreateModal}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body px-0 py-0">
                <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradiotable"
                      id="btnradiotable1"
                      autoComplete="off"
                      checked
                    />
                    <label
                      className="btn btn-white px-3 mb-0"
                      htmlFor="btnradiotable1"
                    >
                      Todos
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradiotable"
                      id="btnradiotable2"
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-white px-3 mb-0"
                      htmlFor="btnradiotable2"
                    >
                      Membros
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradiotable"
                      id="btnradiotable3"
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-white px-3 mb-0"
                      htmlFor="btnradiotable3"
                    >
                      Gestores
                    </label>
                  </div>
                  <div className="input-group w-sm-25 ms-auto">
                    <span className="input-group-text text-body">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      placeholder="Pesquisar"
                      onChange={(e) => setFilterData(e.target.value)}
                      defaultValue={filterData}
                      onKeyDown={handleKeyDownFilter}
                    />
                  </div>
                </div>
                <div className="table-responsive p-0">
                  {loading && <ProgressLoading />}
                  <table className="table align-items-center mb-0">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7">
                          Programas
                        </th>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">
                          Datas
                        </th>
                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                          Estado
                        </th>
                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                          Data de Registo
                        </th>
                        <th className="text-secondary opacity-7"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {programs?.map((program: any) => (
                        <tr>
                          <td>
                            <div className="d-flex px-2 py-1">
                              <div className="d-flex flex-column justify-content-center ms-1">
                                <h6 className="mb-0 text-sm font-weight-semibold">
                                  {program?.title}
                                </h6>
                                <p className="text-sm text-secondary mb-0">
                                  {program?.email}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-secondary text-sm font-weight-normal">
                              {moment(program?.startEventDate).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                            <br />
                            <span className="text-secondary text-sm font-weight-normal">
                              {moment(program?.endEventDate).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          </td>
                          <td className="align-middle text-center text-sm">
                            {program?.isActive ? (
                              <span className="badge badge-sm border border-success text-success bg-success">
                                Activo
                              </span>
                            ) : (
                              <span className="badge badge-sm border border-danger text-danger bg-danger">
                                Desactivado
                              </span>
                            )}
                          </td>
                          <td className="align-middle text-center">
                            <span className="text-secondary text-sm font-weight-normal">
                              {moment(program?.createdAt).format("DD/MM/YYYY")}
                            </span>
                          </td>
                          <td className="align-middle">
                            <Link
                              to={"/admin/talent-pool/programs/" + program?.id}
                              className="text-secondary font-weight-bold text-xs"
                              data-bs-toggle="tooltip"
                              data-bs-title="Edit program"
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 15 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z"
                                  fill="#64748B"
                                />
                              </svg>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="border-top py-3 px-3 d-flex align-items-center">
                  <p className="font-weight-semibold mb-0 text-dark text-sm">
                    Pagina {dataFetched?.page} de {dataFetched?.totalPages} -{" "}
                    {dataFetched?.totalCount} registos
                  </p>
                  <div className="ms-auto">
                    <button
                      className="btn btn-sm btn-white mb-0"
                      disabled={dataFetched?.page === 1}
                      onClick={() =>
                        dataFetched?.page > 1 &&
                        setCurrentPage(dataFetched?.page - 1)
                      }
                    >
                      Anterior
                    </button>
                    <button
                      className="btn btn-sm btn-white mb-0"
                      disabled={dataFetched?.page === dataFetched?.totalPages}
                      onClick={() =>
                        dataFetched?.page < dataFetched?.totalPages &&
                        setCurrentPage(dataFetched?.page + 1)
                      }
                    >
                      Proximo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
