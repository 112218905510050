import React from "react";
import { Col, Row } from "react-bootstrap";

const Dashboard: React.FC = () => {
  return (
    <Row style={{ minHeight: "60vh" }}>
      <Col lg={8} md={10} sm={12} style={{ padding: "2em 15px" }}>
        <h3>Seja bem-vindo ao </h3>
        <h1>Mulemba App!</h1>
        <h4>Onde crescem ideias e carreiras</h4>
        <hr />
        <p>
          A Mulemba App é um aplicativo criado para promover a integração no
          mercado de trabalho e o desenvolvimento profissional dos jovens,
          mediante a preparação dos mesmos para os processos de recrutamento e
          selecção, bem como o desenvolvimento de competências e capacidades
          requeridas no mercado de trabalho com base nas necessidades actuais e
          perspectivas de evolução do contexto laboral.
        </p>
        <p>
          O nosso compromisso é transformar jovens estudantes em profissionais
          de alto desempenho, que as melhores empresas desejam atrair e reter,
          através da mentoria, formação e networking.
        </p>
        <p>
          A Mulemba é uma árvore sagrada e muito conhecida em Angola e em várias
          partes da África. Na tradição angolana, a Mulemba é vista como um
          símbolo de sabedoria, conhecimento e protecção. É comum que as pessoas
          se reúnam debaixo dela para compartilhar histórias, ensinar licções e
          discutir assuntos importantes.{" "}
        </p>
        <p>
          A Mulemba App é o espaço ideal para compartilhar experiências,
          desenvolver habilidades para a resolução de problemas e melhorar a sua
          rede de contactos.
        </p>
        Mulemba App - Onde crescem ideais e carreiras!
      </Col>
    </Row>
  );
};

export default Dashboard;
