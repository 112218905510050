import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { apiRequest } from "../../../../../../../utils/api";
import {
  ProgressLoading,
  PulseLoading,
} from "../../../../../../Common/components/Loading";
import { TextEditor } from "../../../ProgramCreate/TextEditor";

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { MyDTPicker } from "../../../../../../../components/MyDTPicker";
import { watch } from "fs";
import MentorCreate from "../Mentors/MentorCreate";
import MentorList from "../Mentors/MentorList";

const EventCreate = ({
  show,
  setShow,
  refresh,
  event = undefined,
}: {
  refresh: () => void;
  show: boolean;
  event: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ zIndex: 9999999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {event?.id ? "Actualizar" : "Registar novo"} Evento
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EventCreateForm
          event={event}
          closeModal={handleClose}
          refresh={refresh}
        />
      </Modal.Body>
    </Modal>
  );
};

interface EventType {
  title: string;
  description: string;
  date: string;
  duration: string;
  programId?: string;
  isActive?: boolean;
}

const EventCreateForm = ({
  refresh,
  closeModal,
  event,
}: {
  refresh: () => void;
  closeModal: () => void;
  event: any;
}) => {
  const [error, setErrors] = useState<any>({});
  const [savedResponse, setSavedResponse] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<EventType>({
    defaultValues: {
      ...event,
      programId: event?.program?.id,
      startEventDate:
        event?.startEventDate &&
        moment(event?.startEventDate).format("YYYY-MM-DD"),
      endEventDate:
        event?.endEventDate && moment(event?.endEventDate).format("YYYY-MM-DD"),
    },
  });

  const onSubmit: SubmitHandler<EventType> = (data: any) => formSubmit(data);

  useEffect(() => {
    if (savedResponse !== undefined) {
      reset();
      refresh();
      closeModal();
    }
  }, [savedResponse]);

  const formSubmit = (body: EventType) => {
    setLoading(true);
    apiRequest({
      endpoint: "/events" + (event?.id ? "/" + event?.id : ""),
      method: event?.id ? "put" : "post",
      body,
    })
      .then((data) => {
        setSavedResponse(data);
      })
      .catch(({ data }) => setErrors(data))
      .finally(() => setLoading(false));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ minWidth: "400px", margin: "0 auto", padding: 15 }}
    >
      {error?.error && (
        <div className="alert alert-danger">{error?.error?.message}</div>
      )}
      <Row>
        <Col xs={12}>
          <label htmlFor="title">Titulo</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="title"
            placeholder="Titulo"
            type="text"
            {...register("title", { required: "O título é obrigatório!" })}
          ></input>
          <span className="error">
            {errors.title && <p>{errors.title.message}</p>}
          </span>
        </Col>

        <Col xs={12}>
          <label htmlFor="title">Descrição</label>

          <TextEditor
            {...register("description", {
              required: "A descrição é obrigatória!",
            })}
            control={control}
          />
          <span className="error">
            {errors.description && <p>{errors.description.message}</p>}
          </span>
        </Col>

        <Col md={6}>
          <label htmlFor="title">Data e hora de inicio</label>

          <MyDTPicker
            placeholder="Data de inicio"
            {...register("date", { required: "Date is required" })}
            control={control}
          />
          <span className="error">
            {errors.date && <p>{errors.date.message}</p>}
          </span>
        </Col>

        <Col md={6}>
          <label htmlFor="title">Duração (Horas)</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="title"
            placeholder="Duração em horas"
            type="number"
            min={0.5}
            max={100}
            step={0.5}
            {...register("duration", { required: "Date is required" })}
          ></input>
          <span className="error">
            {errors.duration && <p>{errors.duration.message}</p>}
          </span>
        </Col>

        <Col>
          {event?.id && (
            <div className="my-2">
              <hr />
              <h5>Mentores</h5>
              <MentorList event={event} />
            </div>
          )}

          <hr />
          <Stack direction="horizontal" gap={3}>
            <button
              type="button"
              onClick={() => closeModal()}
              className="mas-auto btn btn-light btn-icon d-flex align-items-center me-2"
            >
              <span className="btn-inner--text">Cancelar</span>
            </button>
            <button
              type="submit"
              className="btn btn-dark btn-icon d-flex align-items-center me-2"
              disabled={loading}
            >
              <span className="btn-inner--icon"></span>
              <span className="btn-inner--text">Salvar</span>
              {loading && <PulseLoading />}
            </button>
          </Stack>
        </Col>
      </Row>
      {loading && <ProgressLoading />}
    </form>
  );
};

export default EventCreate;
