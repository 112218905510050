import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import { LayoutBanner } from "../LayoutBanner";

export const HelpDesk = () => {
  const navigate = useNavigate();
  const [{ data: users, ...data }, setUsers] = useState<any>({});

  return (
    <>
      <LayoutBanner
        name="Help Desk"
        description=" Assistência Técnica e Suporte Especializado Sempre ao Seu Lado"
      />

 {/*      <Header /> */}
      <div className="row">
        <div className="col-12">
          <div className="card border shadow-xs mb-4">
            <div className="card-body px-0 py-0">
              <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradiotable"
                    id="btnradiotable1"
                    autoComplete="off"
                    checked
                  />
                  <label
                    className="btn btn-white px-3 mb-0"
                    htmlFor="btnradiotable1"
                  >
                    Todos
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradiotable"
                    id="btnradiotable2"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-white px-3 mb-0"
                    htmlFor="btnradiotable2"
                  >
                    Membros
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradiotable"
                    id="btnradiotable3"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-white px-3 mb-0"
                    htmlFor="btnradiotable3"
                  >
                    Gestores
                  </label>
                </div>
                <div className="input-group w-sm-25 ms-auto">
                  <span className="input-group-text text-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pesquiser"
                  />
                </div>
              </div>
              <div className="table-responsive p-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-3 col-sm-6 mb-xl-0"
          onClick={() => navigate("/admin/help-desk/chat")}
        >
          <div className="card border shadow-xs mb-4">
            <div className="card-body text-start p-3 w-100">
              <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                <svg
                  height="16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="w-100">
                    <p className="text-sm text-secondary mb-1">Programs</p>
                    <h4 className="mb-2 font-weight-bold">Chat</h4>
                    <div className="d-flex align-items-center">
                      <span className="text-sm ms-1">
                        Use este chat para atender, esclarecer dúvidas e
                        solucionar problemas dos clientes de forma eficiente e
                        profissional.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
