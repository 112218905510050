import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ProgramCreateForm } from "./ProgramCreateForm";
export const ProgramCreate = ({
  show,
  setShow,
  refresh,
  program = undefined,
}: any) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {program?.id ? "Actualizar" : "Registar novo"} Programa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgramCreateForm
          program={program}
          closeModal={handleClose}
          refresh={refresh}
        />
      </Modal.Body>
    </Modal>
  );
};
