import { LayoutBanner } from "../../LayoutBanner";

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { ProgressLoading } from "../../../Common/components/Loading";
import { UserCreate } from "../../Users/UserCreate/UserCreate";
import { useApi } from "../../../../hooks/useApi";
import { FaqManagerSkeleton } from "./FaqManagerSkeleton";
import {
  ChevronRight,
  FileQuestion,
  ListRestart,
  Plus,
  RotateCcw,
} from "lucide-react";
import { FaqCreate } from "./FaqCreate/FaqCreate";
import { truncate } from "lodash";

export interface IFaq {
  question: string;
  answer: string;
  isActive: boolean;
  createdAt: string;
  id: string;
}

export const FaqManager = () => {
  const [faqs, setFaqs] = useState<IFaq[]>();
  const [filterData, setFilterData] = useState<string>();
  const [refresh, setRefresh] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);
  const [showUserCreateModal, setShowUserCreateModal] = useState(false);

  const {
    loading,
    resolve,
    data: dataFetched,
  } = useApi({
    method: "GET",
    url: "/common/faqs",
  });

  const handleKeyDownFilter = (event: any) => {
    if (!(event.key === "Enter")) return;

    if (filterData && filterData.length > 0) {
      resolve({
        params: { question: filterData },
      });
    }
  };

  useEffect(() => {
    resolve({
      params: { page: currentPage, limit: 6 },
    });
  }, [currentPage, refresh]);

  useEffect(() => {
    if (dataFetched?.data) setFaqs(dataFetched?.data);
  }, [dataFetched]);

  if (loading) return <FaqManagerSkeleton />;

  return (
    <>
      <LayoutBanner name="FAQs" description="Gestão das perguntas frequentes" />

      <div className="row">
        <div className="col-12">
          <div className="card border shadow-xs mb-4">
            <div className="card-header border-bottom pb-0">
              <div className="d-sm-flex align-items-center">
                <div>
                  <h6 className="font-weight-semibold text-lg mb-0">
                    Perguntas frequentes já registadas
                  </h6>
                </div>
                <div className="ms-auto d-flex">
                  <button
                    type="button"
                    className="btn btn-sm btn-white me-2"
                    onClick={() => {
                      setFilterData("");
                      resolve();
                    }}
                  >
                    Ver todos
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2"
                    onClick={() => setShowUserCreateModal(true)}
                  >
                    <Plus size={14} className="me-2" />
                    <span className="btn-inner--text">Registar nova</span>
                  </button>

                  <FaqCreate
                    refresh={() => setRefresh(Math.random())}
                    show={showUserCreateModal}
                    setShow={setShowUserCreateModal}
                  />
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                <div className="input-group w-sm-25 ms-auto">
                  <span className="input-group-text text-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    placeholder="Pesquisar pergunta"
                    onChange={(e) => setFilterData(e.target.value)}
                    defaultValue={filterData}
                    onKeyDown={handleKeyDownFilter}
                  />
                </div>
              </div>
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="text-secondary text-xs font-weight-semibold opacity-7">
                        FAQ
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Estado
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Data de Registo
                      </th>
                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {faqs?.map((faq) => (
                      <tr>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div className="d-flex flex-column justify-content-center ms-1">
                              <h6 className="mb-0 text-sm font-weight-semibold">
                                {truncate(faq?.question, { length: 80 })}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle text-center text-sm">
                          {faq?.isActive ? (
                            <span className="badge badge-sm border border-success text-success bg-success">
                              Activo
                            </span>
                          ) : (
                            <span className="badge badge-sm border border-danger text-danger bg-danger">
                              Desactivado
                            </span>
                          )}
                        </td>
                        <td className="align-middle text-center">
                          <span className="text-secondary text-sm font-weight-normal">
                            {moment(faq?.createdAt).format("DD/MM/YYYY")}
                          </span>
                        </td>
                        <td className="align-middle">
                          <Link
                            to={"" + faq?.id}
                            className="text-secondary font-weight-bold text-xs"
                            data-bs-toggle="tooltip"
                            data-bs-title="Edit user"
                          >
                            <ChevronRight size={15} />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="border-top py-3 px-3 d-flex align-items-center">
                <p className="font-weight-semibold mb-0 text-dark text-sm">
                  Pagina {dataFetched?.page} de {dataFetched?.totalPages} - {" "}
                  {dataFetched?.totalCount} registos
                </p>
                <div className="ms-auto">
                  <button
                    className="btn btn-sm btn-white mb-0"
                    disabled={dataFetched?.page === 1}
                    onClick={() =>
                      dataFetched?.page > 1 &&
                      setCurrentPage(dataFetched?.page - 1)
                    }
                  >
                    Anterior
                  </button>
                  <button
                    className="btn btn-sm btn-white mb-0"
                    disabled={dataFetched?.page === dataFetched?.totalPages}
                    onClick={() =>
                      dataFetched?.page < dataFetched?.totalPages &&
                      setCurrentPage(dataFetched?.page + 1)
                    }
                  >
                    Proximo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
