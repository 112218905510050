


import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { apiRequest } from '../../../../utils/api';
import Input from '../../../../components/Forms/Input';
import { Buttom, Select, TextArea } from '../../../../components/Forms';
import { useNavigate } from 'react-router-dom';
import { ProgressLoading, PulseLoading } from '../../../Common/components/Loading';
import { toast } from 'react-toastify';
interface PropertyFormInputs {
    email: string;
    password: string;
    role: string;
}
export const UserCreateForm = ({refresh, setShow}:any) => {

    const [error, setErrors] = useState<any>();
    const [loading,setLoading]=useState<boolean>(false)
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<PropertyFormInputs>();

    const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) =>
        formSubmit(data);

    useEffect(() => {
        if (savedResponse !== undefined) {
            refresh();
            setShow(false)
        }
    }, [savedResponse])

    const formSubmit = (body: PropertyFormInputs) => {
        setLoading(true)
        apiRequest({
            endpoint: '/users',
            method: 'post',
            body
        }).then((data) => {
            
            if (data?.id) {
                refresh();
                setShow(false)
                 toast.success("Utilizador Registado com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            } else {

                 toast.error("Erro ao registado o Utilizador: "+data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
                setErrors(data)
        }
        }).catch(({data})=>setErrors(data))
        .finally(()=>setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', margin: '0 auto', padding: 15 }}>
            
            {error && <div className='alert alert-danger'>{error?.message}</div>}

            <div>
                <label htmlFor="title">Email</label>
                <input
                    className="form-control ps-0"
                    maxLength={256}
                    data-name="email"
                    placeholder="E-mail"
                    type="email"

                    {...register("email", { required: "Email is required" })}
                >
                </input>
                <span className='error'>{errors.email && <p>{errors.email.message}</p>}</span>
            </div>

            <div>
                <label htmlFor="title">Palavra passe</label>
                <input
                    className="form-control ps-0"
                    maxLength={256}
                    data-name="Name"
                    placeholder="Palavra-passe"
                    type="password"

                    {...register("password", { required: "Password is required" })}
                >
                </input>
                <span className="error">{errors.password && <p>{errors.password.message}</p>}</span>
            </div>

            <div>
                <label htmlFor="title">Tipo de utilizador</label>
                <select
                    className="form-control ps-0"

                    data-name="Name"

                    {...register("role", { required: "role is required" })}
                >
                    <option>USER</option>
                    <option>MENTOR</option>
                    <option>ADMIN</option>
                </select>
                <span className="error">{errors.role && <p>{errors.role.message}</p>}</span>
            </div>

            <hr />
            <button type="submit" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2" >
            {loading 
                ? <PulseLoading />
                :<span className="btn-inner--icon">

                </span>}
                <span className="btn-inner--text">Registar novo</span>

            </button>
            {loading && <ProgressLoading />}
        </form>
    );
}
