import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { RootState } from "../../app/store";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export const UserBackOfficeLayout = ({ children }: any) => {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  return (
    <>
      <main className="main-content max-height-vh-100 h-100">
        <nav className="navbar bg-slate-900 navbar-expand-lg flex-wrap top-0 px-0 py-0">
          <div className="container py-2 position-relative">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <div className="d-flex align-items-center">
                  <img src="/logo.jpeg" style={{ width: 30 }} />
                  <span className="px-3 font-weight-bold text-lg text-white me-4">
                    Mulemba App
                  </span>
                </div>
              </nav>
              <ul className="navbar-nav d-none d-lg-flex">
                <li className="nav-item px-3 py-3 border-radius-sm  d-flex align-items-center">
                  <Link
                    to="https://everest40.com/#about"
                    target="_blank"
                    className="nav-link text-white p-0"
                  >
                    Sobre Nós
                  </Link>
                </li>
                <li className="nav-item px-3 py-3 border-radius-sm  d-flex align-items-center">
                  <Link
                    to="https://everest40.com/#contact"
                    target="_blank"
                    className="nav-link text-white p-0"
                  >
                    Contactos
                  </Link>
                </li>
                <li className="nav-item px-3 py-3 border-radius-sm  d-flex align-items-center">
                  <Link to="faqs" className="nav-link text-white p-0">
                    FAQs
                  </Link>
                </li>
                <li className="nav-item dropdown position-relative  py-3 d-flex align-items-center">
                  <Link
                    to="javascript:;"
                    className="nav-link text-white p-0"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-sm position-relative">
                      <img
                        src={`https://ui-avatars.com/api/?name=${(
                          auth?.user?.name ??
                          auth?.user?.email ??
                          ""
                        )
                          .split(" ")
                          .join("+")}`}
                        className="w-100 border-radius-md"
                      />
                    </div>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4 "
                    aria-labelledby="dropdownMenuButton"
                    style={{ cursor: "default", zIndex: 9999 }}
                  >
                    <li className="mb-2">
                      <div
                        className="card shadow-none"
                        style={{
                          minWidth: "15rem",
                          border: "none",
                          backgroundColor: "none",
                        }}
                      >
                        <Link to={"profile"}>
                          <>
                            <img
                              src={
                                auth?.user?.userData?.avatar ??
                                `https://ui-avatars.com/api/?name=${(
                                  auth?.user?.name ??
                                  auth?.user?.email ??
                                  ""
                                )
                                  .split(" ")
                                  .join("+")}`
                              }
                              className="card-img-top"
                              alt="..."
                            />
                          </>
                        </Link>

                        <div
                          className="card-body"
                          style={{ cursor: "default" }}
                        >
                          <h5 className="card-title">
                            {auth?.user?.userData?.firstName}{" "}
                            {auth?.user?.userData?.lastName}{" "}
                          </h5>
                          <p className="card-text">{auth?.user?.role}</p>
                          <Link
                            to="#"
                            className="btn btn-dark mb-0"
                            onClick={handleLogout}
                          >
                            Terminar sessão
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <hr className="horizontal w-100 my-0 dark" />
          <div className="container pb-3 pt-3">
            <ul className="navbar-nav d-none d-lg-flex">
              <li className="nav-item border-radius-sm py-3 me-2  d-flex align-items-center">
                <Link to="/" className="nav-link text-white p-0">
                  Início
                </Link>
              </li>
              <li className="nav-item border-radius-sm px-3 py-3 me-2  d-flex align-items-center">
                <Link to="/my/talent-pool" className="nav-link text-white p-0">
                  Laboratório de talentos
                </Link>
              </li>
              <li className="nav-item border-radius-sm px-3 py-3 me-2  d-flex align-items-center">
                <Link to="/my/chat" className="nav-link text-white p-0">
                  Chat
                </Link>
              </li>

              {/*
                            <li className="nav-item border-radius-sm px-3 py-3 me-2  d-flex align-items-center">
                                <Link to="/my/perfil" className="nav-link text-white p-0">
                                    Perfil
                                </Link>
                            </li>
                            <li className="nav-item border-radius-sm px-3 py-3 me-2  d-flex align-items-center">
                                <Link to="/my/calendar" className="nav-link text-white p-0">
                                    Calendario
                                </Link>
                            </li>
                             */}
            </ul>
            <div className="ms-md-auto p-0 d-flex align-items-center w-sm-20">
              <div className="input-group border-dark">
                <span className="input-group-text border-dark bg-dark text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="opacity-8"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control border-dark bg-dark"
                  placeholder="Pesquisar"
                />
              </div>
            </div>
          </div>
        </nav>
        <div
          className="pt-5 pb-6 bg-cover"
          style={{
            backgroundImage: "url('/assets/img/header-blue-purple.jpg')",
          }}
        ></div>
        <div className="container">
          {children}
          <Outlet />
          <footer className="footer pt-3  ">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-lg-between">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <div className="copyright text-center text-xs text-muted text-lg-start">
                    Copyright © 2024 Mulemba App by
                    <Link
                      to="https://hiperbite.ao"
                      className="text-secondary"
                      target="_blank"
                    >
                      Hiperbite.ao
                    </Link>
                    .
                  </div>
                </div>
                <div className="col-lg-6">
                  <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                    <li className="nav-item">
                      <Link
                        to="https://www.creative-tim.com/presentation"
                        className="nav-link text-xs text-muted"
                        target="_blank"
                      >
                        Sobre Nós
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="https://www.creative-tim.com"
                        className="nav-link text-xs text-muted"
                        target="_blank"
                      >
                        Contactos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="https://www.creative-tim.com/blog"
                        className="nav-link text-xs text-muted"
                        target="_blank"
                      >
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="https://www.creative-tim.com/license"
                        className="nav-link text-xs pe-0 text-muted"
                        target="_blank"
                      >
                        Licença
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
};
