import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApi } from "../../../../../hooks/useApi";
import { FaqDeleteSkeleton } from "./FaqDeleteSkeleton";

export const FaqDelete = ({
  faq,
  children,
}: {
  faq: any;
  children: React.ReactNode;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span onClick={handleShow}>{children}</span>
      <DeleteModel show={show} setShow={setShow} faq={faq} />
    </>
  );
};

const DeleteModel = ({
  show,
  setShow,
  faq,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  faq: any;
}) => {
  const { resolve, loading, error } = useApi({ method: "DELETE" });

  const navigate = useNavigate();

  const handleDeleteFaq = async () => {
    const resolved = await resolve({
      url: "/common/faqs/" + faq?.id,
    });

    if (resolved?.data) {
      toast.success("FAQ Eliminado com sucesso!");
      navigate("/admin/faqs");
    } else if (error) {
      toast.error(
        "Não foi possivel eliminar o Programa, por favor tente mais tarde; se o erro persistir contacte o administrador de sistema!"
      );
    }
  };

  if (loading) return <FaqDeleteSkeleton show={show} />;

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Programa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Atenção:</p>

          <p>
            Você está prestes a Eliminar a FAQ <b>{faq?.question}</b> do
            sistema. Tem certeza de que deseja continuar? A remoção da FAQ não
            tem volta.
          </p>

          <Button className="btn btn-danger" onClick={handleDeleteFaq}>
            <span className="btn-inner--icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
              </svg>
            </span>
            <span className="btn-inner--text">Confirmo, Eliminar FAQ</span>
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
