import React, { useState, useRef, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { Button, Form } from "react-bootstrap";
import { getCroppedImg } from "../../utils/getCroppedImg";
import { toast } from "react-toastify";
import { PulseLoading } from "../../pages/Common/components/Loading";
import { useApi } from "../../hooks/useApi";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

export function ImageCropper({
  handleModal,
  setRefresh,
userData
}: {
  userData: any,
  handleModal: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { loading, resolve, error, data } = useApi({
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data", // Pode ser omitido, o axios define automaticamente
    }
  });


  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImageSrc(reader.result as string);
    }
  };

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveImage = async () => {
    try {
      if (!imageSrc || !croppedAreaPixels) return;

      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      const formData = new FormData();

      formData.append("file", croppedImage, "cropped-image.jpg");

      
      const response = await resolve({
        body: formData,
        url: `/users-data/upload-avatar/${userData?.id}`
      });

      if (!response) 
        throw new Error();

      alert("imagem salva!");

      setRefresh(Math.random() / 100);

      handleModal();

    } catch (err: any) {
      toast.info("Erro ao enviar imagem: " + error);
    }
  };

  return (
    <div className="container  mt-4">
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={onSelectFile}
        className="d-none"
      />
      <Button
        onClick={() => inputRef.current?.click()}
        variant="outline-secondary"
      >
        Escolher Imagem
      </Button>

      {imageSrc && (
        <div
          className="position-relative w-100 mt-3"
          style={{ height: "300px", backgroundColor: "#eee" }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
      )}

      {imageSrc && (
        <div className="mt-3">
          <Form.Label>Zoom: {zoom.toFixed(1)}x</Form.Label>
          <Form.Range
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e) => setZoom(parseFloat(e.target.value))}
          />
        </div>
      )}

      {/*  {imageSrc && (
        <Button onClick={showCroppedImage} variant="warning" className="mt-3">
          Cortar Imagem
        </Button>
      )} */}

      {imageSrc && (
        <div className="d-flex justify-content-end mt-4">
          <button
            type="submit"
            className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2"
            disabled={loading}
            onClick={saveImage}
          >
            {loading ? (
              <PulseLoading />
            ) : (
              <span className="btn-inner--icon"></span>
            )}
            <span className="btn-inner--text">Salvar imagem</span>
          </button>
        </div>
      )}
    </div>
  );
}
