import React, { useEffect, useState } from "react";
import { RoleEnum } from "../../data/constants";
import { jwtDecode } from "jwt-decode";

export function Permission({
  permissions,
  children,
}: {
  permissions: Array<keyof typeof RoleEnum>;
  children: React.ReactNode;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const user: any = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token") ?? "")
    : undefined;


  useEffect(() => {
    if (user?.role && permissions.find((p) => RoleEnum[p] == user?.role)) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [permissions]); // Dependências agora incluem `session` e `permissions`

  return (
    <>{isAuthenticated ? <>{children}</> : null}</>
  );
}
