import React from "react";
const IMG_BG = "../../assets/img/header-blue-purple.jpg";

export function LayoutBanner({
  name,
  description,
}: {
  name: string;
  description?: string;
}) {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card card-background card-background-after-none align-items-start mt-4 mb-5">
            <div
              className="full-background"
              style={{
                backgroundImage: `url('${IMG_BG}')`,
              }}
            ></div>
            <div className="card-body text-start p-4 w-100">
              <h3 className="text-white mb-2">{name} 🔥</h3>
              <p className="my-5 font-weight-semibold">
                {description ?? "Check all the advantages and choose the best."}
              </p>
              <button
                type="button"
                className="btn btn-outline-white btn-blur btn-icon d-flex align-items-center mb-0 d-none"
              >
                <span className="btn-inner--icon">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="d-block me-2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.61036 4.52196C6.34186 4.34296 5.99664 4.32627 5.71212 4.47854C5.42761 4.63081 5.25 4.92731 5.25 5.25V8.75C5.25 9.0727 5.42761 9.36924 5.71212 9.52149C5.99664 9.67374 6.34186 9.65703 6.61036 9.47809L9.23536 7.72809C9.47879 7.56577 9.625 7.2926 9.625 7C9.625 6.70744 9.47879 6.43424 9.23536 6.27196L6.61036 4.52196Z"
                    />
                  </svg>
                </span>
              </button>
              <img
                src="/assets/img/3d-cube.png"
                alt="3d-cube"
                className="position-absolute top-0 end-1 w-25 max-width-200 mt-n6 d-sm-block d-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
