import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiRequest } from '../../../../../../utils/api';
import { ProgressLoading } from '../../../../../Common/components/Loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const DeleteData = ({userData,user, refresh}:any) => {


    const [show, setShow] = useState<boolean>(false);
    const handleShow = () => setShow(true);

    return (<>
        



    <div className="card card-frame">
      <div className="card-body ">
        Eliminar Utilizador

        <hr/>
                
        <Button variant='danger' onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
          Eliminar conta
        </Button>
                <DeleteModel show={show} setShow={setShow} user={ user} userData={ userData} />    
      </div>
    </div>
    </>)
}



const ProgramDelete = ({ program, children }: any) => {

    const [show, setShow] = useState<boolean>(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <span onClick={handleShow}>{children}</span>
            <DeleteModel show={show} setShow={setShow} program={ program} />    
</>
    )
}

const DeleteModel = ({ show, setShow, user, userData }: any) => {
    
    const [loading,setLoading]=useState<any>()
    const navigate = useNavigate()

    const handleDeletProgram = () => {
         setLoading(true)
        apiRequest({
            endpoint: '/users/' + user?.id,
            method: 'delete',
        }).then(( data : any) => {
            debugger
            if (data?.id !== undefined) {
                navigate('/admin/users');
                  toast.success("Utilizador "+user?.email+ " Eliminado com sucesso!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
              } else {
                toast.error('Não foi possivel eliminar o Utilizador, por favor tente mais tarde,. se o erro persistir contacte o administrador de sistema: '+data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            }
            
        }).catch(err => console.error(err))
        .finally(()=>setLoading(false))
     }
    
    return (<>
     <Modal show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar  Utilizador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <p>
                    Atenção:</p>

                    <p>Você está prestes a Eliminar o Utilizador <b>{ user?.email}</b> do sistema.

Tem certeza de que deseja continuar? A remoção do programa não tem volta.</p>
<p>
                    Aviso: Certifique-se de que não há dados importantes associados ao utilizador antes de prosseguir.
                    </p>
                
                <Button className='btn btn-danger' disabled={ loading} onClick={handleDeletProgram}>
                    
                    
                        <span className="btn-inner--icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                    </svg>
                    </span>
                <span className="btn-inner--text" >Confirmo, Eliminar Programa</span>
                </Button>
                {loading && <ProgressLoading />}
            </Modal.Body>
        </Modal>
    </>)
}
export default DeleteData
