import React from "react";
import styles from "./chatList.module.scss";

const ChatListSkeleton: React.FC = () => {
  return (
    <div className={styles["chat-list-content"]}>
      {/* Campo de pesquisa */}
      <div className="d-relative mb-3">
        <label htmlFor="search" className="position-absolute mt-2 ms-2">
          <div className="placeholder rounded-circle" style={{ width: "15px", height: "15px" }}></div>
        </label>
        <div className="placeholder-glow">
          <div className="placeholder w-100 rounded" style={{ height: "35px" }}></div>
        </div>
      </div>

      <hr />

      {/* Skeleton para os itens da lista de chats */}
      <ul className="list-group">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index}>
            <li className="list-group-item p-0 border-0 ps-2 my-2 rounded-0 cursor-pointer bg-transparent">
              <div className="position-relative d-flex align-items-center gap-2 justify-between p-2">
                <div className="placeholder rounded-circle" style={{ width: "50px", height: "50px" }}></div>
                <div className="placeholder-glow">
                  <div className="placeholder col-8"></div>
                </div>
              </div>
            </li>
            <hr />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ChatListSkeleton;
