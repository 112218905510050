import React from "react";
import { Col, Row } from "react-bootstrap";

export const FaqDetailSkeleton = () => {
  return (
    <div className="container py-3">
      <Row>
        <Col md={12}>
          <div className="d-md-flex align-items-center mb-3 mx-2">
            <div className="placeholder-glow mb-md-0 mb-3">
              <span className="placeholder col-6 h-3"></span>
            </div>
            <div className="placeholder-glow ms-md-auto">
              <span className="placeholder col-3 h-3"></span>
            </div>
            <div className="placeholder-glow ms-2">
              <span className="placeholder col-2 h-3"></span>
            </div>
          </div>
        </Col>
      </Row>
      
      <Row className="mt-5">
        <Col md={4} sm={12} xs={12}>
          <div className="card border shadow-xs mb-4">
            <div className="card-body text-start p-3 w-100">
              <div className="placeholder-glow mb-3">
                <span className="placeholder col-2 h-3"></span>
              </div>
              <div className="placeholder-glow">
                <span className="placeholder col-10 h-4"></span>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} sm={12} xs={12}>
          <div className="card border shadow-xs mb-4">
            <div className="card-body text-start p-3 w-100">
              <div className="placeholder-glow mb-3">
                <span className="placeholder col-2 h-3"></span>
              </div>
              <div className="placeholder-glow">
                <span className="placeholder col-12 h-5"></span>
                <span className="placeholder col-12 h-5 mt-2"></span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

