import React, { useEffect, useState } from "react";
import { Col, ListGroup, Modal, Row } from "react-bootstrap";

import { apiRequest } from "../../../../../../../utils/api";
import {
  ProgressLoading,
  PulseLoading,
} from "../../../../../../Common/components/Loading";
import { useDebounce } from "@uidotdev/usehooks";
import { useApi } from "../../../../../../../hooks/useApi";
import { toast } from "react-toastify";

const MentorCreate = ({
  show,
  setShow,
  refresh,
  program,
  event = undefined,
}: {
  program: any;
  show: boolean;
  refresh: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  event: any;
}) => {
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ zIndex: 99999999, backgroundColor: "#0000005b" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Mentor ao Programa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MentorCreateForm
          program={program}
          closeModal={handleClose}
          refresh={refresh}
          event={event}
        />
      </Modal.Body>
    </Modal>
  );
};

const MentorCreateForm = ({
  refresh,
  closeModal,
  program,
  event,
}: {
  refresh: () => void;
  closeModal: () => void;
  program: any;
  event: any;
}) => {
  const [error, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [savedResponse, setSavedResponse] = useState<any>(undefined);
  const [userFilter, setUserFilter] = useState<string>("");
  const [selectedUser, setUser] = useState<any>({});
  const [eventId, setEventId] = useState<string>();
  const [{ data: users }, setUsers] = useState<any>({ data: [] });

  const debouncedSearchTerm = useDebounce(userFilter, 500);

  const {
    data: eventsResolved,
    resolve,
    loading: loadingEvents,
  } = useApi({
    method: "GET",
    url: "/events",
    params: {
      page: 1,
      limit: 100,
      programId: program?.id,
    },
  });

  useEffect(() => {
    if (!event) resolve();
  }, [program]);

  useEffect(() => {
    if (userFilter?.length > 2) {
      if (debouncedSearchTerm) {
        setLoading(true);
        apiRequest({
          endpoint: "/users",
          method: "get",
          urlQueryParams: { email: userFilter, role: "MENTOR" },
        })
          .then((data) => {
            setUsers(data);
          })
          .catch(({ data }) => setErrors(data))
          .finally(() => setLoading(false));
      }
    } else {
      setUsers([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (savedResponse !== undefined) {
      toast.success("Mentor adicionado com sucesso!");
      refresh();
      closeModal();
    }
  }, [savedResponse]);

  const formSubmit = () => {
    setLoading(true);
    apiRequest({
      endpoint: "/event-mentors",
      method: "post",
      body: { eventId: event?.id ?? eventId, userId: selectedUser?.id },
    })
      .then((data) => {
        setSavedResponse(data);

        refresh();
        closeModal();
      })
      .catch(({ data }) => setErrors(data))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div style={{ maxWidth: "600px", margin: "0 auto", padding: 15 }}>
        {error?.error && (
          <div className="alert alert-danger">{error?.error?.message}</div>
        )}
        <Row>
          <Col xs={12}>
            <label htmlFor="title">Mentor</label>
            <input
              className="form-control ps-0"
              maxLength={256}
              data-name="title"
              placeholder="Escreva o email de um mentor para adicionar ao programa"
              type="text"
              onChange={(e: any) => setUserFilter(e.target.value)}
            ></input>
          </Col>

          <Col xs={12}>
            <ListGroup defaultActiveKey="#link1">
              {users?.map((user: any, i: number) => (
                <ListGroup.Item
                  action
                  onClick={() => setUser(user)}
                  active={user?.id === selectedUser?.id}
                >
                  <div className="d-flex px-2">
                    <div className="avatar avatar-sm rounded-circle bg-gray-100 me-2 my-2">
                      <img
                        src="/assets/img/unknow.png"
                        className="w-160"
                        alt="spotify"
                      />
                    </div>
                    <div className="my-auto">
                      <h6 className="mb-0 text-sm">
                        <b>{user?.email}</b>
                      </h6>
                      <small className="mb-0 text-sm">{user?.role}</small>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
              {users?.length === 0 && (
                <ListGroup.Item disabled>
                  <div className="d-flex px-2 text-center">
                    <small className="mb-0 text-sm text-center">
                      {" "}
                      :: Nenhum mentor encontrado
                    </small>
                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>

          {!event && (
            <Col xs={12}>
              <label htmlFor="title">Selecione o Evento | Tarefa</label>
              <select
                className="form-select form-select-sm"
                aria-label="Small select example"
                onChange={(e) => setEventId(e.target.value)}
              >
                {eventsResolved?.data?.map((e: any) => (
                  <option value={e?.id}>{e?.title}</option>
                ))}
              </select>

              {loading && (
                <div className="mt-2">
                  <ProgressLoading />
                </div>
              )}
            </Col>
          )}

          <Col>
            <hr />
            <button
              type="button"
              onClick={formSubmit}
              className="btn btn-dark btn-icon d-flex align-items-center me-2"
            >
              {loading ? (
                <PulseLoading />
              ) : (
                <span className="btn-inner--icon"></span>
              )}
              <span className="btn-inner--text">Salvar</span>
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MentorCreate;
