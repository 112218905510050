import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BadgeHelp, LayoutDashboard, LayoutGrid, SquareMousePointer, Users } from "lucide-react";

export const ManagerBackOfficeLayout = ({ children }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/"); // Fallback route
    }
  };

  const isCurrentMenu = (key: string) =>
    location?.pathname?.indexOf(key) > -1 ? "active" : "";
  return (
    <>
      <aside
        className="sidenav navbar navbar-vertical navbar-expand-xs border-0 bg-slate-900 fixed-start "
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a
            className="navbar-brand d-flex align-items-center m-0"
            href=" https://demos.creative-tim.com/corporate-ui-dashboard/pages/dashboard.html "
            target="_blank"
          >
            <span className="font-weight-bold text-lg">Mulemba App</span>
          </a>
        </div>
        <div
          className="collapse navbar-collapse px-4  w-auto "
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item py-2">
              <Link
                className={"nav-link " + isCurrentMenu("/dashboard")}
                to={"/admin/dashboard"}
              >
                <LayoutDashboard size={15} />
                <span className="font-weight-normal text-md ms-3">Dashboard </span>
              </Link>
            </li>

            <li className="nav-item py-2">
              <Link
                className={"nav-link  " + isCurrentMenu("/users")}
                to={"/admin/users"}
              >
                <Users size={15} />
                <span className="font-weight-normal text-md ms-3">
                  Utilizadores
                </span>
              </Link>
            </li>
            <li className="nav-item py-2">
              <Link
                className={"nav-link  " + isCurrentMenu("/talent-pool")}
                to={"/admin/talent-pool"}
              >
                <LayoutGrid size={15} />
                <span className="font-weight-normal text-md ms-3">
                  Laboratório de Talentos
                </span>
              </Link>
            </li>
            <li className="nav-item py-1">
              <Link
                className={"nav-link  " + isCurrentMenu("/help-desk")}
                to={"/admin/help-desk"}
              >
                <SquareMousePointer size={15} />
                <span className="font-weight-normal text-md ms-3">HelpDesk</span>
              </Link>
            </li>
            <li className="nav-item py-2">
              <Link
                className={"nav-link  " + isCurrentMenu("/faqs")}
                to={"/admin/faqs"}
              >
                <BadgeHelp size={15} />
                <span className="font-weight-normal text-md ms-3">FAQs</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <nav
          className="navbar navbar-main navbar-expand-lg mx-5 px-0 shadow-none rounded"
          id="navbarBlur"
          navbar-scroll="true"
        >
          <div className="container-fluid py-1 px-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent mb-1 pb-0 pt-1 px-0 me-sm-6 me-5">
                <li className="breadcrumb-item text-sm">
                  <a
                    className="opacity-5 text-dark"
                    href="javascript:;"
                    onClick={handleGoBack}
                  >
                    {"<"}
                  </a>
                </li>
                <li className="breadcrumb-item text-sm">
                  <a className="opacity-5 text-dark" href="javascript:;">
                    Dashboard
                  </a>
                </li>
              </ol>

              <h6 className="font-weight-bold mb-0">Dashboard</h6>
            </nav>
            <div
              className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
              id="navbar"
            >
              <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                <div className="input-group">
                  <span className="input-group-text text-body bg-white  border-end-0 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control ps-0"
                    placeholder="Pesquisar"
                  />
                </div>
              </div>
              <ul className="navbar-nav  justify-content-end">
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                  <a
                    href="javascript:;"
                    className="nav-link text-body p-0"
                    id="iconNavbarSidenav"
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                      <i className="sidenav-toggler-line"></i>
                    </div>
                  </a>
                </li>
                <li className="nav-item px-3 d-flex align-items-center">
                  <a href="javascript:;" className="nav-link text-body p-0">
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fixed-plugin-button-nav cursor-pointer"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <a
                    href="javascript:;"
                    className="nav-link text-white p-0"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-sm position-relative">
                      <img
                        src="/assets/img/team-2.jpg"
                        alt="profile_image"
                        className="w-100 border-radius-md"
                      />
                    </div>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li className="mb-2">
                      <div className="card" style={{ minWidth: "18rem" }}>
                        <img
                          src="/assets/img/team-2.jpg"
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body">
                          <h5 className="card-title">{auth?.user?.email} </h5>
                          <p className="card-text">{auth?.user?.role}</p>
                          <a
                            href="#"
                            className="btn btn-dark mb-0"
                            onClick={handleLogout}
                          >
                            Terminar sessão
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="main-container" className="container-fluid py-4 px-5">
          <div className="row">
            {children}

            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
};
