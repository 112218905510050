import React from "react";

export const ProgramsSkeleton = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card border shadow-xs mb-4">
            <div className="card-header border-bottom pb-0">
              <div className="d-sm-flex align-items-center">
                <div className="placeholder-glow w-50">
                  <h6 className="placeholder col-6 mb-0"></h6>
                </div>
                <div className="ms-auto d-flex gap-2">
                  <div className="placeholder-glow">
                    <span className="placeholder btn btn-sm btn-white col-6"></span>
                  </div>
                  <div className="placeholder-glow">
                    <span className="placeholder btn btn-sm btn-dark col-8"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body px-0 py-0">
              <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                <div className="btn-group">
                  <span className="placeholder btn btn-white col-3 me-2"></span>
                  <span className="placeholder btn btn-white col-3 me-2"></span>
                  <span className="placeholder btn btn-white col-3"></span>
                </div>
                <div className="input-group w-sm-25 ms-auto">
                  <span className="input-group-text placeholder col-1"></span>
                  <input
                    type="text"
                    className="form-control placeholder"
                    disabled
                  />
                </div>
              </div>

              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="text-secondary text-xs font-weight-semibold opacity-7">
                        Programas
                      </th>
                      <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">
                        Datas
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Estado
                      </th>
                      <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">
                        Data de Registo
                      </th>
                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(5)].map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex px-2 py-1">
                            <div className="d-flex flex-column justify-content-center ms-1 placeholder-glow">
                              <span className="placeholder col-6 mb-2"></span>
                              <span className="placeholder col-4"></span>
                            </div>
                          </div>
                        </td>
                        <td className="placeholder-glow">
                          <span className="placeholder col-6 d-block mb-1"></span>
                          <span className="placeholder col-6 d-block"></span>
                        </td>
                        <td className="align-middle text-center placeholder-glow">
                          <span className="placeholder btn btn-sm col-6"></span>
                        </td>
                        <td className="align-middle text-center placeholder-glow">
                          <span className="placeholder col-5"></span>
                        </td>
                        <td className="align-middle text-center placeholder-glow">
                          <span className="placeholder col-2"></span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="border-top py-3 px-3 d-flex align-items-center">
                <p className="font-weight-semibold mb-0 text-dark text-sm placeholder-glow col-4">
                  <span className="placeholder col-12"></span>
                </p>
                <div className="ms-auto d-flex gap-2">
                  <span className="placeholder btn btn-sm btn-white col-3"></span>
                  <span className="placeholder btn btn-sm btn-white col-3"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
