import { useEffect } from "react";
import { loginUser } from "../../../features/auth/authSlice";
import { OauthCredentials } from "../../../features/auth/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";

const GOOGLE_CLIENT_ID =
  "1062096640055-ign0d5r5rgq65ttjrv1joj6k8lpalou3.apps.googleusercontent.com";

const GoogleAuth: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Inicializa o Google Identity Services
    (window as any).google?.accounts?.id?.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });

    // Renderiza o botão de login
    (window as any).google?.accounts?.id?.renderButton(
      document.getElementById("google-login-button"),
      { theme: "outline", size: "large" }
    );
  }, []);

    const handleCredentialResponse = (response: any) => {
      debugger
    dispatch(
      loginUser({
        token: response.credential,
        provider: "google",
      } as OauthCredentials)
    );
  };

  return <div id="google-login-button"></div>;
};

export default GoogleAuth;
