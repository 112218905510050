import { useState, useCallback } from "react";
import axiosInstance from "../utils/axiosInstance";

interface IApiOptions {
  method: "GET" | "POST" | "PUT" | "DELETE" | "HEAD";
  headers?: Record<string, string>;
  body?: unknown;
  params?: unknown;
  url?: string;
}

export interface IUseApiResult {
  loading: boolean;
  error: any;
  data: Array<{ [key in string]: string }> | any;
  resolve: (options?: Partial<IApiOptions>) => Promise<any>;
}

export const useApi = ({
  method,
  url,
  params,
  body,
  headers,
}: IApiOptions): IUseApiResult => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  const resolve = useCallback(
    async (options: Partial<IApiOptions> = {}) => {
      setLoading(true);
      setError(null);

      const {
        method: customMethod,
        url: customUrl,
        params: customParams,
        body: customBody,
        headers: customHeaders,
      } = options;
      debugger;
      try {
        // URL dinâmica com parâmetros
        const queryString =
          customParams || params
            ? "?" +
              Object.entries(customParams || params || {})
                .map(
                  ([key, value]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                )
                .join("&") +
              "&culture=pt"
            : "?culture=pt";

        const isFormData = (customBody || body) instanceof FormData;

        const response = await axiosInstance({
          headers: {
            ...headers,
            ...customHeaders,
            ...(isFormData ? {} : { "Content-Type": "multipart/form-data" }), // Evita erro no multipart
          },
          url: (customUrl || url) + queryString,
          method: customMethod || method,
          data:
            (customMethod || method) === "GET" ||
            (customMethod || method) === "HEAD"
              ? undefined
              : isFormData
              ? customBody || body
              : JSON.stringify(customBody || body),
        });

        setData(response.data);

        setError(null);

        return response.data;
      } catch (error: any) {
        setError(error);
        setData(null);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [method, url, params, body]
  );

  return {
    resolve,
    loading,
    error,
    data,
  };
};
