import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  extractPreview,
  IExtractPreview,
  parseText,
} from "../../../../../utils";
import {
  PreviewMessageDocLink,
  PreviewMessageImageLink,
  PreviewMessageLink,
} from "../chat-preview/Preview";

import styles from "./chatBumble.module.scss";

interface ChatBumblePropsType {
  type: "reply" | "sent";
  message: any;
}

const ChatBumble = ({ type, message }: ChatBumblePropsType) => {
  const [preview, setPreview] = useState<IExtractPreview>();
  const [imageView, setImagePreview] = useState<IExtractPreview>();
  const [pdfView, setPdfPreview] = useState<IExtractPreview>();

  const getPreviewDetail = async () => {
    const prev = await extractPreview(message.content);

    if (prev) setPreview(prev);
    else if (message?.imageUrl) {
      setImagePreview({ image: message?.imageUrl });
    } else if (message?.fileUrl) {
      setPdfPreview({ image: message?.fileUrl });
    }
  };

  useEffect(() => {
    getPreviewDetail();
  }, [message]);

  const htmlString = parseText(message.content ?? "");

  return (
    <div
      className={`${styles["message"]} ${
        message?.type === "LOG"
          ? styles[message?.type?.toLowerCase()]
          : styles[type]
      }`}
      title={message?.member?.user?.email}
    >
      <div className={styles["box"]}>
        {message?.type !== "LOG" && type !== "sent" && (
          <img
            className="border-0 rounded-circle icon-img"
            width={35}
            src={
              "https://ui-avatars.com/api/?name=" + message?.member?.user?.email
            }
          />
        )}
        <p style={{ width: "80%", maxWidth: 400 }}>
          <small style={{ fontSize: 10 }}>
            <b>
              {message?.member?.user?.userData?.firstName
                ? message?.member?.user?.userData?.firstName +
                  " " +
                  message?.member?.user?.userData?.lastName
                : message?.member?.user?.email}
            </b>
          </small>


          {preview && <PreviewMessageLink preview={preview} />}

          {imageView && <PreviewMessageImageLink preview={imageView} />}

          {pdfView && <PreviewMessageDocLink preview={pdfView} />}

          <div dangerouslySetInnerHTML={{ __html: htmlString }} />

          <small style={{ fontSize: 10 }}>
            {moment(message?.createdAt).fromNow()}
          </small>
        </p>
        {message?.type !== "LOG" && type === "sent" && (
          <img
            className="border-0 rounded-circle icon-img"
            width={35}
            src={
              "https://ui-avatars.com/api/?name=" + message?.member?.user?.email
            }
          />
        )}
      </div>
    </div>
  );
};

export default ChatBumble;
