import { Col, Row, Stack } from "react-bootstrap";
import PageLink from "./PageLink";


export type Props = {
    currentPage: number;
    lastPage: number;
  maxLength: number;
    totalCount?:number,
  setCurrentPage: (page: number) => void;
    type?:"simple"|"default"
  };
  
export default function Pagination({
  currentPage,
  lastPage,
  maxLength,
  setCurrentPage,
  totalCount = 0,

  type = "simple"
}: Props) {
  
  return <Row>
        <Col style={{padding:15}}>
          
        { type === "default"
          ? <DefaultPagination
            currentPage={currentPage}
            lastPage={lastPage}
            maxLength={maxLength}
            totalCount={ 0}
            setCurrentPage={setCurrentPage}
          />
          : <SimplePagination
            currentPage={currentPage}
            lastPage={lastPage}
            maxLength={maxLength}
            setCurrentPage={setCurrentPage}
          />}
            
          
        </Col>
      </Row>
    
  }

function DefaultPagination({
    currentPage,
    lastPage,
    maxLength,
    setCurrentPage,
  }: Props) {
    const pageNums = [1, 2, 3];
  
    return (
      <nav className="pagination" aria-label="Pagination">
        <PageLink
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </PageLink>
        {pageNums.map((pageNum, idx) => (
          <PageLink
            key={idx}
            active={currentPage === pageNum}
            disabled={isNaN(pageNum)}
            onClick={() => setCurrentPage(pageNum)}
          >
            {pageNum}
          </PageLink>
        ))}
        <PageLink
          disabled={currentPage === lastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </PageLink>
      </nav>
    );
  }
const SimplePagination = ({
  currentPage,
    lastPage,
    maxLength,
    setCurrentPage,
}: any) => {
  
  
  return <>
  <div className="py-3 px-3 d-flex align-items-center">
                                <p className="font-weight-semibold mb-0 text-dark text-sm">Pagina {currentPage} de {lastPage} - {maxLength} registos</p>
                                <div className="ms-auto">
                                    <button className="btn btn-sm btn-white mb-0" disabled={ currentPage===1} onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}>Anterior</button>
                                    <button className="btn btn-sm btn-white mb-0" disabled={ currentPage===lastPage} onClick={() => currentPage < lastPage && setCurrentPage(currentPage + 1)}>Proximo</button>
                                </div>
                            </div>
  </>
}