import { File, ImagePlus, Loader2, Paperclip, Send } from "lucide-react";
import React from "react";
import { AddImageFile } from "./add-image/AddImageFile";
import { AddFile } from "./add-file/AddFile";
interface MessageContentProps {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: () => void;
  loading: boolean;
  message: string;
  onSelectImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function MessageContent({
  sendMessage,
  setMessage,
  loading,
  message,
  onSelectImage,
  onSelectFile,
}: MessageContentProps) {
  return (
    <div className="d-flex align-items-center gap-1">
      <ul className="dropdown-menu">
        <li className="" title="Ficheiro">
          <AddFile onSelectFile={onSelectFile} />
        </li>
        <li className="" title="Imagem">
          <AddImageFile onSelectImage={onSelectImage} />
        </li>
      </ul>

      <button data-bs-toggle="dropdown" disabled={loading}>
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <Paperclip size={20} />
        )}
      </button>

      <textarea
        onChange={(e) => setMessage(e.target.value)}
        className="form-control shadow-none"
        placeholder="Write your message..."
        name=""
        rows={1}
        id=""
        value={message}
      ></textarea>

      <button onClick={sendMessage} disabled={loading}>
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <Send size={20} />
        )}
      </button>
    </div>
  );
}
