import { toast } from "react-toastify";
import { useApi } from "../../../../../hooks/useApi";
import React, { useEffect } from "react";

export const FaqState = ({
  faq,
  children,
  handleUpdate,
  setUpdating,
}: {
  faq: any;
  children: React.ReactNode;
  handleUpdate: () => void;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { resolve, loading, error, data } = useApi({
    method: "PUT",
  });

  const handleDeletProgram = async () => {
    const newFaqState = await resolve({
      url: "/common/faqs/" + faq?.id,
      body: { isActive: !faq?.isActive },
    });

    setUpdating(loading);
    
    if (newFaqState?.data) {
      toast.success(
        "Estado da FAQ Alterado para " +
          (newFaqState?.data.isActive ? "Activado" : "Desactivado") +
          "  com sucesso!"
      );
      handleUpdate();
    } else if (error) {
      toast.error(
        "Não foi possivel Alterar o estado da FAQ, por favor tente mais tarde,. se o erro persistir contacte o administrador de sistema"
      );
    }
  };

  if (loading) setUpdating(loading);

  return (
    <>
      <span onClick={handleDeletProgram}>{children}</span>
    </>
  );
};
