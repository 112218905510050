import { useEffect } from "react";
import { useApi } from "../../../hooks/useApi";

export function Faq() {
  const { data, loading, resolve } = useApi({
    url: "/common/faqs",
    method: "GET",
  });

  useEffect(() => {
    resolve();
  }, []);

  return (
    <>
      <h1 className="mt-5">Perguntas Frequentes</h1>

      {loading ? (
        // Skeleton Loader
        <div className="my-5">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="mb-3 p-3 border rounded">
              <div className="placeholder-glow">
                <span className="placeholder col-8"></span>
              </div>
              <div className="placeholder-glow mt-2">
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-6"></span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className="accordion accordion-flush my-5"
          id="accordionFlushExample"
        >
          {data?.data?.map((faq: any, index: number) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed shadow-none font-weight-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse${index}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapse${index}`}
                >
                  {faq.question}
                </button>
              </h2>
              <div
                id={`flush-collapse${index}`}
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <div
                    className="prose text-sm text-justify"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
