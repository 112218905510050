export async function getCroppedImg(imageSrc: string, croppedAreaPixels?: any): Promise<Blob> {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => (image.onload = resolve));
  
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Canvas context is not available");
  
    canvas.width = croppedAreaPixels.width ?? image.width;
    canvas.height = croppedAreaPixels.height ?? image.height;
  
    ctx.drawImage(
      image,
      croppedAreaPixels.x ?? image.x,
      croppedAreaPixels.y ?? image.y,
      croppedAreaPixels.width ?? image.width,
      croppedAreaPixels.height ?? image.height,
      0,
      0,
      croppedAreaPixels.width ?? image.width,
      croppedAreaPixels.height ?? image.height,
    );
  
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) throw new Error("Falha ao criar Blob");
        resolve(blob);
      }, "image/jpeg");
    });
  }
  