import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useApi } from "../../../../../hooks/useApi";
import { socket } from "../../../../../utils/socket";
import ChatBumble from "../chat-bumble/ChatBumble";

import styles from "./chatContent.module.scss";
import { File, ImagePlus, Paperclip, Send, X } from "lucide-react";
import { MessageContent } from "./MessageContent";
import { getCroppedImg } from "../../../../../utils/getCroppedImg";
import ChatContentSkeleton from "./chatContentSkeleton";
import { Image, Stack } from "react-bootstrap";

interface Message {
  id: number;
  chatId: number;
  user: string;
  content: string;
  createdAt: string;
}

const ChatContent = ({ chat, user, full, member, className }: any) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
   
  );
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);

  const {
    data,
    resolve,
    loading: loadingData,
  } = useApi({
    method: "GET",
    url: "/common/messages",
    params: { page: 1, limit: 100, chatId: chat?.id },
  });

  const { resolve: resolveAttachImage, loading: loadingAttachImage } = useApi({
    method: "POST",
    url: "/common/messages/attach-image",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const { resolve: resolveAttachFile, loading: loadingAttachFile } = useApi({
    method: "POST",
    url: "/common/messages/attach-file",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const { resolve: resolveRemoveAttachedImage } = useApi({
    method: "DELETE",
  });

  const { resolve: resolveRemoveAttachedFile } = useApi({
    method: "DELETE",
  });

  // Função para enviar mensagem
  const sendMessage = async () => {
    if (message.trim().length === 0) return;

    setSendMessageLoading(true);

    socket.emit(
      "sendMessage",
      {
        chatId: chat?.id,
        memberId: member?.id,
        content: message.trim(),
        imageUrl,
        fileUrl,
      },
      (response: any) => {
        setMessage("");

        if (response?.status === "success") {
          setFileUrl(null);
          setImageUrl(null);
          resolve();
        } else {
          toast.error("Erro ao enviar mensagem: ", response?.error);
          onRemoveSelectedFile();
        }

        setSendMessageLoading(false);
      }
    );
  };

  const onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingFile(true);

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const formData = new FormData();

      formData.append("file", file);

      const attachedData = await resolveAttachImage({
        body: formData,
      });

      setImageUrl(attachedData?.data?.result?.url);

      setLoadingFile(false);
    }
  };

  const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingFile(true);

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const formData = new FormData();

      formData.append("file", file);

      const attachedData = await resolveAttachFile({
        body: formData,
      });

      setFileUrl(attachedData?.data?.result?.url);

      setLoadingFile(false);
    }
  };

  const onRemoveSelectedFile = async () => {
    resolveRemoveAttachedFile({
      url: `/common/messages/remove-attached-file?fileUrl=${fileUrl}`,
    });

    setFileUrl(null);
  };

  const onRemoveSelectedImage = async () => {
    resolveRemoveAttachedImage({
      url: `/common/messages/remove-attached-image?imageUrl=${imageUrl}`,
    });

    setImageUrl(null);
  };

  useEffect(() => {
    if (data) {
      setMessages(data?.data);
    }
    setLoading(loadingData);
  }, [data]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (!chat?.id) return;

    setLoading(true);

    const chatId = chat.id;

    // Entrar no chat
    socket.emit("joinChat", chatId);

    socket.off("newMessage");

    socket.on("newMessage", (newMessage: any) => {
      setMessages((prev) => [...prev, newMessage]);
    });

    resolve();

    return () => {
      socket.emit("leaveChat", chatId);
      socket.off("receiveMessage");
    };
  }, [chat?.id]);

  if (loading) return <ChatContentSkeleton />;

  return (
    <>
      {chat && user ? (
        <div
          className={`d-flex flex-column position-absolute ${styles["main"]}`}
          style={{ width: full ? "auto" : "100%" }}
        >
          <div className="d-flex align-items-center gap-2 justify-between shadow-md p-3 px-4 py-3">
            <img
              className="rounded-circle"
              width={35}
              src={`https://ui-avatars.com/api/?name=${chat.title
                .split(" ")
                .join("+")}`}
              alt=""
            />
            <div className="fw-bold">{chat?.title}</div>
          </div>

          <div
            className={`pt-4 px-4 ${styles["message-box"]}`}
            ref={chatContainerRef}
          >
            <div className="pb-3">
              {messages.map((message: any) => (
                <ChatBumble
                  key={message.id}
                  type={user?.id === message.member?.userId ? "sent" : "reply"}
                  message={message}
                />
              ))}
            </div>
          </div>

          {imageUrl && (
            <div className={styles["image-attached"]}>
              <img className="p-2" src={imageUrl} alt="" />
              <p>
                <X
                  size={20}
                  className="cursor-pointer"
                  onClick={() => onRemoveSelectedImage()}
                />
              </p>
            </div>
          )}


          <div className={`p-2 ${styles["message-content"]}`}>
            {imageUrl && (
              <Stack
                direction="horizontal"
                gap={4}
                className={styles["image-attached"]}
              >
                <Image className="p-2" thumbnail src={imageUrl} alt="" />

                <X
                  size={20}
                  className="cursor-pointer ms-auto"
                  onClick={() => onRemoveSelectedImage()}
                />
              </Stack>
            )}
            {fileUrl && (
              <Stack
                direction="horizontal"
                className={styles["image-attached"]}
                style={{overflow:'hidden'}}
              >
                
                  <iframe src={fileUrl} frameBorder="0" height={100}></iframe>
                
                <X
                  size={20}
                  className="cursor-pointer ms-auto"
                  onClick={() => onRemoveSelectedFile()}
                />
              </Stack>
            )}
            {member?.id && (
              <MessageContent
                sendMessage={sendMessage}
                loading={
                  loadingAttachImage ||
                  sendMessageLoading ||
                  loadingData ||
                  loadingFile ||
                  loadingAttachFile
                }
                message={message}
                setMessage={setMessage}
                onSelectImage={onSelectImage}
                onSelectFile={onSelectFile}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChatContent;
