import { Image } from "react-bootstrap";
import { IExtractPreview, truncateText } from "../../../../../utils";

export function PreviewMessageLink({ preview }: { preview: IExtractPreview }) {
  return (
    <>
      <a href={preview?.url ?? "#"} target="_blank" rel="noopener noreferrer">
        <>
          <div className="card border-0 my-3">
            <img src={preview?.image} className="card-img-top" />
            <div className="card-body">
              <h6 className="card-title">{preview?.title || " ... "}</h6>
              <div className="card-text">
                {truncateText(preview?.description, 53) || " ... "}
              </div>
            </div>
          </div>
        </>
      </a>
    </>
  );
}

export function PreviewMessageImageLink({
  preview,
}: {
  preview: IExtractPreview;
}) {
  return (
    <>
      <a href={preview?.image ?? "#"} target="_blank" rel="noopener noreferrer">          
            <Image thumbnail src={preview?.image} className="card-img-top" />
      </a>
    </>
  );
}

export function PreviewMessageDocLink({
  preview,
}: {
  preview: IExtractPreview;
}) {
  return (
    <>
      <a href={preview?.image ?? "#"} target="_blank" rel="noopener noreferrer">
        <>
          <div className="my-2">
            <div className="card shadow-sm p-3 d-flex flex-row align-items-center border-0">
              <img
                src="https://cdn-icons-png.flaticon.com/128/337/337946.png"
                alt="Ícone PDF"
                width="50"
              />
              <div className="ms-3">
                <p className="mb-1 fw-bold text-sm">Documento PDF</p>
              </div>
            </div>
          </div>
        </>
      </a>
    </>
  );
}
