import React from "react";

export const FaqCreateFormSkeleton = () => {
  return (
    <div className="container py-3">
      {/* Skeleton para alerta de erro */}
      <div className="placeholder-glow mb-4">
        <span className="placeholder col-12 h-3"></span>
      </div>
      
      {/* Skeleton para Pergunta */}
      <div className="mb-3">
        <div className="placeholder-glow mb-2">
          <span className="placeholder col-3 h-2"></span>
        </div>
        <div className="placeholder-glow">
          <span className="placeholder col-12 h-3"></span>
        </div>
      </div>
      
      {/* Skeleton para Resposta */}
      <div className="mb-3">
        <div className="placeholder-glow mb-2">
          <span className="placeholder col-3 h-2"></span>
        </div>
        <div className="placeholder-glow">
          <span className="placeholder col-12 h-5"></span>
        </div>
      </div>
      
      <hr />
      
      {/* Skeleton para botão */}
      <div className="placeholder-glow mt-3">
        <span className="placeholder col-4 h-3"></span>
      </div>
    </div>
  );
};

