import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { apiRequest } from "../../../../../utils/api";
import { Dot, Search } from "lucide-react";
import styles from "./chatList.module.scss";
import ChatListSkeleton from "./ChatListSkeleton";

export const ChatList = ({
  user,
  selectChat,
  selectedChat,
}: {
  user: any;
  selectChat: any;
  selectedChat: any;
}) => {
  const [{ data: myChats }, setMyChats] = useState<any>({ data: [] });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const filter = user?.role === "ADMIN" ? {} : { userId: user?.id };
    if (user?.id) {
      setLoading(true);
      apiRequest({
        endpoint: "/common/chat-memberships",
        method: "get",
        urlQueryParams: { page: 1, limit: 100, ...filter },
      })
        .then(setMyChats)
        .finally(() => setLoading(false));
    }
  }, [user]);

  return (
    <div>
      {loading ? (
        <ChatListSkeleton />
      ) : (
        <div className={styles["chat-list-content"]}>
          <div className="d-relative">
            <label htmlFor="search" className="position-absolute mt-2 ms-2">
              <Search size={15} />
            </label>
            <input
              type="text"
              id="search"
              className={`form-control shadow-none outline-none border-none ${styles["search-filter"]}`}
              placeholder="Conversas"
            />
          </div>

          <hr />

          <ul className="list-group">
            {_.uniqBy(myChats, "chat.id")?.map(({ chat }: any) => (
              <>
                <li
                  key={chat?.id}
                  className={`list-group-item p-0 border-0 ps-2 my-2 rounded-0 cursor-pointer bg-transparent text-light ${
                    selectedChat?.id == chat?.id && "border-start border-2"
                  }`}
                  onClick={() => selectChat(chat)}
                >
                  <>
                    <div className="position-relative d-flex align-items-center gap-2 justify-between p-2">
                      <Dot size={50} className={`${styles["status-online"]}`} />
                      <img
                        className="rounded-circle"
                        width={35}
                        src={`https://ui-avatars.com/api/?name=${chat.title
                          .split(" ")
                          .join("+")}`}
                        alt=""
                      />
                      <div className="fw-bold">{chat?.title}</div>
                      {/* <span className="badge text-bg-primary rounded-pill">14</span> */}
                    </div>
                  </>
                </li>
                <hr />
              </>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
