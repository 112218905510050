import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { apiRequest } from "../../../../../../utils/api";
import {
  ProgressLoading,
  PulseLoading,
} from "../../../../../Common/components/Loading";

const ContactData = ({ user, refresh }: any) => {
  const { userData } = user;
  const [editing, setEditing] = useState<boolean>(false);
  return (
    <div>
      <Row>
        <Col md={8}>
          <h3 className="display-6">Contactos</h3>
        </Col>
        <Col md={4}>
          {editing ? (
            <Button
              style={{ float: "right" }}
              size="sm"
              variant="white"
              onClick={() => setEditing(false)}
            >
              X
            </Button>
          ) : (
            <Button
              style={{ float: "right" }}
              size="sm"
              variant="white"
              onClick={() => setEditing(true)}
            >
              Editar
            </Button>
          )}
        </Col>
      </Row>
      {editing ? (
        <ContactDataForm
          userData={{ ...userData, user, email: user.email }}
          refresh={refresh}
          setEditing={setEditing}
        />
      ) : (
        <ContactDataView userData={{ ...userData, user, email: user.email }} />
      )}

      <hr />
    </div>
  );
};
const ContactDataView = ({ userData }: any) => {
  return (
    <>
      <p>Email</p>
      <h5>{userData?.email ?? "-"}</h5>

      <p>Telefone</p>
      <h5>{userData?.telephone ?? "-"}</h5>

      <p>LinkedIn</p>
      <h5>{userData?.linkedIn ?? "-"}</h5>

      <p>Instagram</p>
      <h5>{userData?.instagram ?? "-"}</h5>
    </>
  );
};

interface PropertyFormInputs {
  email: Date;
  linkedIn: string;
  instagram: string;
  telephone: string;
}
export const ContactDataForm = ({ userData, setEditing, refresh }: any) => {
  const [error, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [savedResponse, setSavedResponse] = useState<any>(undefined);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PropertyFormInputs>({
    defaultValues: { ...userData },
  });

  const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) => {
    const { linkedIn, instagram, telephone, email } = data;

    formSubmit({
      /*linkedin, instagram,*/ telephone,
      email,
      linkedIn,
      instagram,
    } as any);
  };

  useEffect(() => {
    if (savedResponse !== undefined) {
      reset();
      refresh();
      setEditing(false);
    }
  }, [savedResponse]);

  const formSubmit = (body: PropertyFormInputs) => {
    setLoading(true);
    apiRequest({
      endpoint: "/users-data/" + userData?.id,
      method: "put",
      body: { user: { email: body.email, id: userData.user?.id }, ...body },
    })
      .then((data) => {
        setSavedResponse(data);
      })
      .catch(({ data }) => setErrors(data))
      .finally(() => setLoading(false));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: "600px", padding: 15 }}
    >
      {error?.error && (
        <div className="alert alert-danger text-dark text-sm" role="alert">
          <strong>Erro: </strong> {error?.error?.message}
        </div>
      )}
      <Row>
        <Col md={12}>
          <label htmlFor="title">E-mail</label>
          <input
            className={"form-control " + (errors.email && "is-invalid")}
            maxLength={256}
            data-name="Name"
            placeholder="Nome completo"
            type="email"
            {...register("email", { required: "Email is required" })}
          ></input>
          <span className="error">
            {errors.email && <p>{errors.email.message}</p>}
          </span>
        </Col>

        <Col md={12}>
          <label htmlFor="title">Telefone</label>
          <input
            className={"form-control " + (errors.telephone && "is-invalid")}
            maxLength={256}
            data-name="telephone"
            placeholder="Número de telefone"
            type="number"
            {...register("telephone", { required: "Telefone is required" })}
          ></input>
          <span className="error">
            {errors.telephone && <p>{errors.telephone.message}</p>}
          </span>
        </Col>

        <Col md={12}>
          <label htmlFor="title">LinkedIn</label>
          <input
            className={"form-control " + (errors.linkedIn && "is-invalid")}
            maxLength={256}
            data-name="linkedIn"
            placeholder="LinkedIn"
            type="text"
            {...register("linkedIn")}
          ></input>
          <span className="error">
            {errors.linkedIn && <p>{errors.linkedIn.message}</p>}
          </span>
        </Col>

        <Col md={12}>
          <label htmlFor="title">Instagram</label>
          <input
            className={"form-control " + (errors.instagram && "is-invalid")}
            maxLength={256}
            data-name="instagram"
            placeholder="Instagram"
            type="text"
            {...register("instagram")}
          ></input>
          <span className="error">
            {errors.instagram && <p>{errors.instagram.message}</p>}
          </span>
        </Col>
      </Row>

      {loading && <ProgressLoading />}
      <hr />
      <button
        type="submit"
        className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2"
        disabled={loading}
      >
        {loading ? <PulseLoading /> : <span className="btn-inner--icon"></span>}
        <span className="btn-inner--text">Registar novo</span>
      </button>
    </form>
  );
};
export default ContactData;
