import React from "react";
import { Modal, Button } from "react-bootstrap";

export const FaqDeleteSkeleton = ({ show }: { show: boolean }) => {
  return (
    <Modal show={show} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>
          <div className="placeholder-glow">
            <span className="placeholder col-6 h-3"></span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="placeholder-glow mb-3">
          <span className="placeholder col-12 h-2"></span>
          <span className="placeholder col-10 h-2"></span>
        </div>

        <div className="placeholder-glow mb-3">
          <span className="placeholder col-8 h-2"></span>
        </div>

        <Button className="btn btn-danger w-100" disabled>
          <span className="placeholder col-6 h-3"></span>
        </Button>
      </Modal.Body>
    </Modal>
  );
};

