import React from "react";
import {  Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaqCreateForm } from "./FaqCreateForm";

export const FaqCreate = ({
  show,
  setShow,
  refresh,
  update = false,
  faq,
}: {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => void;
  update?: boolean;
  faq?: any;
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{update ? "Atualizar" : "Registar nova"} FAQ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FaqCreateForm
          refresh={refresh}
          setShow={setShow}
          update={update}
          faq={faq}
        />
      </Modal.Body>
    </Modal>
  );
};
