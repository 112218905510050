import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../../../../../utils/api";
import EventCreate from "./EventCreate";
import moment from "moment";
import EventDelete from "./EventDelete";
import { ProgressLoading } from "../../../../../../Common/components/Loading";
import { Button, Dropdown, Row } from "react-bootstrap";
import Pagination from "../../../../../../../components/Pagination";

import { toast } from "react-toastify";
import { Plus } from "lucide-react";
import { EventDetailModal } from "./EventDetail";

const EventList = ({ program }: any) => {
  const [{ data: events, totalPages, limit, totalCount, ...data }, setEvents] =
    useState<any>({});
  const [selectedEvent, setEvent] = useState<any>({});

  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showEventCreateModal, setshowEventCreateModal] = useState(false);
  const [showEventModal, setshowEventModal] = useState(false);

  useEffect(() => {
    if (program?.id) {
      setLoading(true);
      apiRequest({
        endpoint: "/events",
        method: "get",
        urlQueryParams: { page: currentPage, limit: 6, programId: program?.id },
      })
        .then((data) => {
          setEvents(data);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [program, currentPage, refresh]);

  const handleEditEvent = (event: any) => {
    setEvent(event);
    setshowEventModal(false);
    setshowEventCreateModal(true);
  };

  const handleViewEvent = (event: any) => {
    setEvent(event);
    setshowEventModal(true);
  };

  return (
    <div className="table-responsive p-0">
      <EventCreate
        refresh={() => setRefresh(Math.random())}
        show={showEventCreateModal}
        event={{ ...selectedEvent, program }}
        setShow={setshowEventCreateModal}
      />

      <EventDetailModal
        refresh={() => setRefresh(Math.random())}
        show={showEventModal}
        event={{ ...selectedEvent, program }}
        setShow={setshowEventModal}
        handleEditEvent={handleEditEvent}
      />

      <table className="table align-items-center justify-content-center mb-0">
        <thead className="bg-gray-100">
          <tr>
            <th className="text-secondary text-xs font-weight-semibold opacity-7">
              Evento
            </th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">
              Data
            </th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">
              Horarios
            </th>
            <th className="text-center text-secondary text-xs font-weight-semibold opacity-7 d-flex align-items-center">
              <Button
                className="mt-3 shadow-none"
                size="sm"
                variant="outline-dark"
                onClick={() => handleEditEvent(undefined)}
              >
                <Plus size={15} />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {events?.map((event: any) => (
            <tr>
              <td>
                <div className="d-flex px-2">
                  <div className="avatar avatar-sm rounded-circle bg-gray-100 me-2 my-2">
                    <span
                      className={
                        "p-1 bg-" +
                        (event?.isActive ? "success" : "danger") +
                        " rounded-circle d-flex ms-auto me-2"
                      }
                    >
                      <span style={{ padding: 5 }}></span>
                    </span>
                  </div>
                  <div className="my-auto">
                    <h6
                      className="mb-0 text-sm"
                      style={{ maxWidth: 400, textWrap: "pretty" }}
                    >
                      {event?.title}
                    </h6>
                  </div>
                </div>
              </td>
              <td>
                <p className="text-sm font-weight-normal mb-0">
                  {moment(event?.date).format("DD/MM/YYYY")}
                </p>
              </td>
              <td>
                <span className="text-sm font-weight-normal">
                  {moment(event?.date).format("HH:mm")} -{" "}
                  {moment(event?.date)
                    .add(event.duration, "hours")
                    .format("HH:mm")}
                  <br />
                  <small>({event?.duration} horas)</small>
                </span>
              </td>
              <td className="align-middle">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    size="sm"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Button}
                      onClick={() => handleViewEvent(event)}
                    >
                      Ver
                    </Dropdown.Item>

                    <Dropdown.Item
                      as={Button}
                      onClick={() => handleEditEvent(event)}
                    >
                      Editar
                    </Dropdown.Item>

                    <EventState
                      event={event}
                      refresh={() => setRefresh(Math.random())}
                      setLoading={setLoading}
                    />

                    <EventDelete
                      event={event}
                      refresh={() => setRefresh(Math.random())}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <ProgressLoading />}
      <Row>
        <Pagination
          currentPage={currentPage}
          lastPage={totalPages}
          maxLength={totalCount}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
        />
      </Row>
    </div>
  );
};

const EventState = ({ event, children, refresh, setLoading }: any) => {
  const handleDeletProgram = () => {
    setLoading(true);
    apiRequest({
      endpoint: "/events/" + event?.id,
      method: "put",
      body: { isActive: !event?.isActive },
    })
      .then(({ data }: any) => {
        if (data?.id !== undefined) {
          toast.success(
            "Evento " + event?.title + " actualizado com sucesso!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
          refresh();
        } else {
          toast.error(
            "Não foi possivel Alterar o estado do Programa, por favor tente mais tarde,. se o erro persistir contacte o administrador de sistema: " +
              data?.message,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            }
          );
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Dropdown.Item as={Button} onClick={handleDeletProgram}>
        {event?.isActive ? "Desactivar" : "Activar "}
      </Dropdown.Item>
    </>
  );
};

export default EventList;
